import { CalendarWeekly } from './CalendarWeekly';
import { CalendarBiweeklyPopular } from './CalendarBiweeklyPopular';
import { CalendarMonthly } from './CalendarMonthly';
import { CalendarStrict } from './CalendarStrict';
import { Visa } from './Visa';
import { MasterCard } from './MasterCard';
import { PetCat } from './PetCat';
import { PetDog } from './PetDog';
import { PetOther } from './PetOther';
import { Oven } from './Oven';
import { Baseboards } from './Baseboards';
import { Cabinets } from './Cabinets';
import { Fridge } from './Fridge';
import { Laundry } from './Laundry';
import { Windows } from './Windows';
import { AmEx } from './AmEx';
import { DiscoverCard } from './DiscoverCard';
import { Message } from './Message';
import { Mail } from './Mail';
import { Gift } from './Gift';

export { CalendarWeekly } from './CalendarWeekly';
export { CalendarBiweeklyPopular } from './CalendarBiweeklyPopular';
export { CalendarMonthly } from './CalendarMonthly';
export { CalendarStrict } from './CalendarStrict';
export { Visa } from './Visa';
export { MasterCard } from './MasterCard';
export { PetCat } from './PetCat';
export { PetDog } from './PetDog';
export { PetOther } from './PetOther';
export { Oven } from './Oven';
export { Baseboards } from './Baseboards';
export { Cabinets } from './Cabinets';
export { Fridge } from './Fridge';
export { Laundry } from './Laundry';
export { Windows } from './Windows';
export { AmEx } from './AmEx';
export { DiscoverCard } from './DiscoverCard';
export { Message } from './Message';
export { Mail } from './Mail';
export { Gift } from './Gift';

export const HAIcons = {
  CalendarWeekly,
  CalendarBiweeklyPopular,
  CalendarMonthly,
  CalendarStrict,
  Visa,
  MasterCard,
  PetCat,
  PetDog,
  PetOther,
  Oven,
  Baseboards,
  Cabinets,
  Fridge,
  Laundry,
  Windows,
  AmEx,
  DiscoverCard,
  Message,
  Mail,
  Gift,
};
