import * as React from 'react';
import type { SingleIconProps } from '../types';
const SvgSpinner = (props: SingleIconProps) => (
  <svg
    width={props.size}
    height={props.size}
    viewBox="0 0 41 40"
    fill="none"
    aria-label={props.accessibilityLabel}
    className={'flex-shrink-0 ' + props.className}
  >
    <g id="CircleNotch">
      <path
        id="Vector"
        d="M36.75 20.0001C36.75 24.3099 35.038 28.4431 31.9905 31.4906C28.943 34.538 24.8098 36.2501 20.5 36.2501C16.1902 36.2501 12.057 34.538 9.00951 31.4906C5.96205 28.4431 4.25 24.3099 4.25 20.0001C4.25 13.5938 7.97031 7.75633 13.7281 5.11415C13.8773 5.04561 14.0385 5.00713 14.2026 5.0009C14.3666 4.99467 14.5303 5.02081 14.6842 5.07783C14.8382 5.13485 14.9794 5.22164 15.0998 5.33323C15.2202 5.44482 15.3174 5.57903 15.3859 5.72821C15.4545 5.87738 15.493 6.03859 15.4992 6.20264C15.5054 6.36668 15.4793 6.53035 15.4223 6.68429C15.3652 6.83823 15.2784 6.97944 15.1669 7.09984C15.0553 7.22024 14.921 7.31749 14.7719 7.38602C9.89844 9.62352 6.75 14.5735 6.75 20.0001C6.75 23.6468 8.19866 27.1442 10.7773 29.7228C13.3559 32.3014 16.8533 33.7501 20.5 33.7501C24.1467 33.7501 27.6441 32.3014 30.2227 29.7228C32.8013 27.1442 34.25 23.6468 34.25 20.0001C34.25 14.5735 31.1016 9.62352 26.2281 7.38602C25.9269 7.24761 25.6929 6.99519 25.5778 6.68429C25.4626 6.37339 25.4757 6.02948 25.6141 5.72821C25.7525 5.42694 26.0049 5.19299 26.3158 5.07783C26.6267 4.96267 26.9706 4.97574 27.2719 5.11415C33.0297 7.75633 36.75 13.5938 36.75 20.0001Z"
        fill={props.color}
      />
    </g>
  </svg>
);
export default SvgSpinner;
