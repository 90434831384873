import * as React from 'react';
import type { SingleIconProps } from '../types';
const SvgCheck = (props: SingleIconProps) => (
  <svg
    width={props.size}
    height={props.size}
    viewBox="0 0 41 40"
    fill="none"
    aria-label={props.accessibilityLabel}
    className={'flex-shrink-0 ' + props.className}
  >
    <g id="Check">
      <path
        id="Vector"
        d="M36.3844 12.1341L16.3844 32.1341C16.2683 32.2503 16.1304 32.3425 15.9787 32.4054C15.8269 32.4683 15.6643 32.5007 15.5 32.5007C15.3357 32.5007 15.1731 32.4683 15.0213 32.4054C14.8696 32.3425 14.7317 32.2503 14.6156 32.1341L5.86563 23.3841C5.63107 23.1495 5.49931 22.8314 5.49931 22.4997C5.49931 22.168 5.63107 21.8499 5.86563 21.6153C6.10018 21.3808 6.4183 21.249 6.75 21.249C7.08171 21.249 7.39982 21.3808 7.63438 21.6153L15.5 29.4825L34.6156 10.3653C34.8502 10.1308 35.1683 9.99902 35.5 9.99902C35.8317 9.99902 36.1498 10.1308 36.3844 10.3653C36.6189 10.5999 36.7507 10.918 36.7507 11.2497C36.7507 11.5814 36.6189 11.8995 36.3844 12.1341Z"
        fill={props.color}
      />
    </g>
  </svg>
);
export default SvgCheck;
