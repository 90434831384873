import React from 'react';
import { CustomIconProps } from './types';

export const Message = ({ size, color }: CustomIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 2H19.5C21.1569 2 22.5 3.34315 22.5 5V15C22.5 16.6569 21.1569 18 19.5 18H18.5V21C18.498 21.4037 18.2534 21.7666 17.88 21.92C17.7613 21.976 17.6312 22.0034 17.5 22C17.2342 22.0015 16.9787 21.8972 16.79 21.71L13.09 18H5.5C3.84315 18 2.5 16.6569 2.5 15V5C2.5 3.34315 3.84315 2 5.5 2ZM19.5 16C20.0523 16 20.5 15.5523 20.5 15V5C20.5 4.44772 20.0523 4 19.5 4H5.5C4.94772 4 4.5 4.44772 4.5 5V15C4.5 15.5523 4.94772 16 5.5 16H13.5C13.7658 15.9985 14.0213 16.1028 14.21 16.29L16.5 18.59V17C16.5 16.4477 16.9477 16 17.5 16H19.5Z"
        fill={color}
      />
    </svg>
  );
};
