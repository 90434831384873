import * as React from 'react';
import type { SingleIconProps } from '../types';
const SvgErrandsActive = (props: SingleIconProps) => (
  <svg
    width={props.size}
    height={props.size}
    viewBox="0 0 64 64"
    fill="none"
    aria-label={props.accessibilityLabel}
    className={'flex-shrink-0 ' + props.className}
  >
    <g id="Errands Selected">
      <g id="Group 1148">
        <g id="Group 1147">
          <rect
            id="Rectangle 7821"
            x={14}
            y={10.8693}
            width={36.3915}
            height={48.1307}
            rx={1}
            fill="#BEBFC4"
          />
          <rect
            id="Rectangle 7825"
            x={16.3478}
            y={13.2177}
            width={31.6958}
            height={43.435}
            rx={1}
            fill="#FFF"
          />
          <path
            id="Subtract"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M35.1305 7.9348C35.1305 8.13581 35.1103 8.3321 35.0718 8.52176H38.6523C39.3006 8.52176 39.8262 9.04734 39.8262 9.69568V14.3914C39.8262 15.0397 39.3006 15.5653 38.6523 15.5653H25.7392C25.0908 15.5653 24.5652 15.0397 24.5652 14.3914V9.69568C24.5652 9.04734 25.0908 8.52176 25.7392 8.52176H29.3196C29.2811 8.3321 29.2609 8.13581 29.2609 7.9348C29.2609 6.31395 30.5749 5 32.1957 5C33.8166 5 35.1305 6.31395 35.1305 7.9348ZM32.1957 9.69568C33.1682 9.69568 33.9566 8.9073 33.9566 7.9348C33.9566 6.96229 33.1682 6.17392 32.1957 6.17392C31.2232 6.17392 30.4348 6.96229 30.4348 7.9348C30.4348 8.9073 31.2232 9.69568 32.1957 9.69568Z"
            fill={props.color}
          />
          <g id="Group 1144">
            <path
              id="Rectangle 7822 (Stroke)"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M25.7392 23.7825H22.2174L22.2174 27.3043H25.7392V23.7825ZM22.2174 22.6086C21.5691 22.6086 21.0435 23.1342 21.0435 23.7825V27.3043C21.0435 27.9526 21.5691 28.4782 22.2174 28.4782H25.7392C26.3875 28.4782 26.9131 27.9526 26.9131 27.3043V23.7825C26.9131 23.1342 26.3875 22.6086 25.7392 22.6086H22.2174Z"
              fill="#BEBFC4"
            />
            <g id="Group 1143">
              <path
                id="Line 6 (Stroke)"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M31.0219 23.7825C31.0219 23.4583 31.2846 23.1956 31.6088 23.1956H41.0002C41.3243 23.1956 41.5871 23.4583 41.5871 23.7825C41.5871 24.1067 41.3243 24.3695 41.0002 24.3695H31.6088C31.2846 24.3695 31.0219 24.1067 31.0219 23.7825Z"
                fill={props.color}
              />
              <path
                id="Line 7 (Stroke)"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M31.0219 26.718C31.0219 26.3938 31.2846 26.131 31.6088 26.131H43.348C43.6722 26.131 43.935 26.3938 43.935 26.718C43.935 27.0422 43.6722 27.305 43.348 27.305H31.6088C31.2846 27.305 31.0219 27.0422 31.0219 26.718Z"
                fill={props.color}
              />
            </g>
          </g>
          <g id="Group 1145">
            <path
              id="Rectangle 7822 (Stroke)_2"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M25.7392 34.3479H22.2174L22.2174 37.8696H25.7392V34.3479ZM22.2174 33.174C21.5691 33.174 21.0435 33.6995 21.0435 34.3479V37.8696C21.0435 38.518 21.5691 39.0435 22.2174 39.0435H25.7392C26.3875 39.0435 26.9131 38.518 26.9131 37.8696V34.3479C26.9131 33.6995 26.3875 33.174 25.7392 33.174H22.2174Z"
              fill="#BEBFC4"
            />
            <g id="Group 1143_2">
              <path
                id="Line 6 (Stroke)_2"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M31.0219 34.3478C31.0219 34.0237 31.2846 33.7609 31.6088 33.7609H41.0002C41.3243 33.7609 41.5871 34.0237 41.5871 34.3478C41.5871 34.672 41.3243 34.9348 41.0002 34.9348H31.6088C31.2846 34.9348 31.0219 34.672 31.0219 34.3478Z"
                fill={props.color}
              />
              <path
                id="Line 7 (Stroke)_2"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M31.0219 37.2826C31.0219 36.9584 31.2846 36.6956 31.6088 36.6956H43.348C43.6722 36.6956 43.935 36.9584 43.935 37.2826C43.935 37.6067 43.6722 37.8695 43.348 37.8695H31.6088C31.2846 37.8695 31.0219 37.6067 31.0219 37.2826Z"
                fill={props.color}
              />
            </g>
          </g>
          <g id="Group 1146">
            <path
              id="Rectangle 7822 (Stroke)_3"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M25.7392 44.9134H22.2174L22.2174 48.4352H25.7392V44.9134ZM22.2174 43.7395C21.5691 43.7395 21.0435 44.2651 21.0435 44.9134V48.4352C21.0435 49.0835 21.5691 49.6091 22.2174 49.6091H25.7392C26.3875 49.6091 26.9131 49.0835 26.9131 48.4352V44.9134C26.9131 44.2651 26.3875 43.7395 25.7392 43.7395H22.2174Z"
              fill="#BEBFC4"
            />
            <g id="Group 1143_3">
              <path
                id="Line 6 (Stroke)_3"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M31.0219 44.9134C31.0219 44.5892 31.2846 44.3264 31.6088 44.3264H41.0002C41.3243 44.3264 41.5871 44.5892 41.5871 44.9134C41.5871 45.2375 41.3243 45.5003 41.0002 45.5003H31.6088C31.2846 45.5003 31.0219 45.2375 31.0219 44.9134Z"
                fill={props.color}
              />
              <path
                id="Line 7 (Stroke)_3"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M31.0219 47.8479C31.0219 47.5238 31.2846 47.261 31.6088 47.261H43.348C43.6722 47.261 43.935 47.5238 43.935 47.8479C43.935 48.1721 43.6722 48.4349 43.348 48.4349H31.6088C31.2846 48.4349 31.0219 48.1721 31.0219 47.8479Z"
                fill={props.color}
              />
            </g>
          </g>
          <path
            id="Vector 6 (Stroke)"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.0192 20.3732C29.2814 20.5638 29.3394 20.9309 29.1487 21.1931L24.049 28.2052L21.8024 25.9586C21.5732 25.7294 21.5732 25.3577 21.8024 25.1285C22.0316 24.8993 22.4033 24.8993 22.6325 25.1285L23.9077 26.4037L28.1993 20.5026C28.39 20.2405 28.7571 20.1825 29.0192 20.3732Z"
            fill={props.color}
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgErrandsActive;
