import * as React from 'react';
import type { SingleIconProps } from '../types';
const SvgJunkRemovalActive = (props: SingleIconProps) => (
  <svg
    width={props.size}
    height={props.size}
    viewBox="0 0 48 48"
    fill="none"
    aria-label={props.accessibilityLabel}
    className={'flex-shrink-0 ' + props.className}
  >
    <path
      d="M11.1661 14.3664C11.1136 13.6185 11.7062 12.9828 12.456 12.9828C21.6135 13.9075 26.6657 13.8746 35.544 12.9828C36.2938 12.9828 36.8864 13.6184 36.834 14.3664L34.9465 41.263C34.9017 41.9008 34.3995 42.4038 33.774 42.4603C33.6961 42.4673 33.6165 42.4729 33.5395 42.4869C26.1132 43.841 21.884 43.845 14.4609 42.4871C14.3837 42.473 14.3038 42.4673 14.2256 42.4602C13.6003 42.4036 13.0983 41.9007 13.0536 41.263L11.1661 14.3664Z"
      fill="#F7F8FA"
    />
    <path
      d="M23.7413 43.5032C26.7414 43.5232 29.7169 43.1839 33.5395 42.4869C33.6046 42.4751 33.6717 42.4692 33.738 42.4634C33.75 42.4624 33.762 42.4613 33.774 42.4603C34.3994 42.4038 34.9017 41.9008 34.9464 41.263L36.8339 14.3664C36.8864 13.6184 36.2938 12.9828 35.544 12.9828C30.952 13.444 26.0905 13.6755 22.4482 13.6636L23.7413 43.5032Z"
      fill="#E4E5E9"
    />
    <path
      d="M15.9828 13.4061C14.4877 13.299 14.1922 13.1581 12.456 12.9828C11.7062 12.9828 11.1136 13.6185 11.1661 14.3664L13.0536 41.263C13.0983 41.9007 13.6003 42.4036 14.2256 42.4602C14.2376 42.4613 14.2497 42.4624 14.2617 42.4634C14.3283 42.4692 14.3955 42.4751 14.4609 42.4871C15.451 42.6682 16.3843 42.8251 17.2759 42.9578L15.9828 13.4061Z"
      fill="#E4E5E9"
    />
    <path
      d="M38.9921 10.6757C38.9921 11.8898 32.2843 12.9827 24 12.9827C15.7157 12.9827 9.00159 11.8749 9.00159 10.6608C9.00159 9.4467 15.7157 8.32751 24 8.32751C32.2843 8.32751 38.9921 9.46165 38.9921 10.6757Z"
      fill="#E4E5E9"
    />
    <path
      d="M11.2502 15.2618C21.2936 16.142 26.6042 15.845 36.7502 15.2932L36.7061 16.1242C26.6057 17.4691 21.4075 17.4171 11.3065 16.1049L11.2502 15.2618Z"
      fill="#737684"
    />
    <path
      d="M21.4138 9.87931V7.2931H26.5862V9.87931H27.8793V6.77586C27.8793 6.34737 27.532 6 27.1035 6H20.8966C20.4681 6 20.1207 6.34737 20.1207 6.77586V9.87931H21.4138Z"
      fill={props.color}
    />
    <path
      d="M9 10.6552C20.715 11.9198 27.2844 11.8868 39 10.6552V14.6178C39 15.0123 38.7005 15.3446 38.3084 15.3881C27.2501 16.6171 20.7521 16.5878 9.69372 15.3865C9.30068 15.3438 9 15.0112 9 14.6159V10.6552Z"
      fill="#BEBFC4"
    />
  </svg>
);
export default SvgJunkRemovalActive;
