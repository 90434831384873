import React from 'react';
import { CustomIconProps } from './types';

export const Gift = ({ size, color }: CustomIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 7.5C14 6.7 13.3 6 12.5 6C11.7 6 11 6.7 11 7.5V9H12.5C13.3 9 14 8.3 14 7.5Z"
        fill={color}
      />
      <path
        d="M8.6 0H1C0.4 0 0 0.4 0 1V8.6C0 8.8 0.2 9 0.4 9H4.4C4 8.2 3.9 7.3 4.2 6.3C4.5 5.3 5.3 4.6 6.3 4.2C7.3 3.9 8.2 4 9 4.4V0.4C9 0.2 8.8 0 8.6 0Z"
        fill={color}
      />
      <path
        d="M19 0H11.4C11.2 0 11 0.2 11 0.4V4.4C11.8 4 12.7 3.9 13.7 4.3C14.6 4.7 15.4 5.4 15.8 6.4C16.2 7.4 16 8.3 15.7 9.1H19.7C19.8 9 20 8.8 20 8.6V1C20 0.4 19.6 0 19 0Z"
        fill={color}
      />
      <path
        d="M19.6 11H12.4L14.4 13C14.6 13.2 14.6 13.4 14.4 13.6L13.6 14.4C13.4 14.6 13.2 14.6 13 14.4L11 12.4V19.6C11 19.8 11.2 20 11.4 20H19C19.6 20 20 19.6 20 19V11.4C20 11.2 19.8 11 19.6 11Z"
        fill={color}
      />
      <path
        d="M7.5 6C6.7 6 6 6.7 6 7.5C6 8.3 6.7 9 7.5 9H9V7.5C9 6.7 8.3 6 7.5 6Z"
        fill={color}
      />
      <path
        d="M6.4 14.4L5.6 13.6C5.4 13.4 5.4 13.2 5.6 13L7.6 11H0.4C0.2 11 0 11.2 0 11.4V19C0 19.6 0.4 20 1 20H8.6C8.8 20 9 19.8 9 19.6V12.4L7 14.4C6.8 14.6 6.6 14.6 6.4 14.4Z"
        fill={color}
      />
    </svg>
  );
};
