import React from 'react';
import colors from '../../../utils/colors';
import { CustomIconProps } from './types';

export const Cabinets = ({
  size,
  color, //= colors.brand.gray.dark,
}: CustomIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 16H68V54.9278C68 55.2922 67.7046 55.5876 67.3402 55.5876H4.65979C4.2954 55.5876 4 55.2922 4 54.9278V16Z"
        fill={color || colors.brand.gray.dark}
      />
      <path
        d="M35.0103 17.3196H66.6804V54.9278C66.6804 55.2922 66.385 55.5876 66.0206 55.5876H35.6701C35.3057 55.5876 35.0103 55.2922 35.0103 54.9278V17.3196Z"
        fill={colors.brand.gray.medium}
      />
      <path
        d="M4 17.3196H34.3505V54.9278C34.3505 55.2922 34.0551 55.5876 33.6907 55.5876H4.65979C4.2954 55.5876 4 55.2922 4 54.9278V17.3196Z"
        fill={colors.brand.gray.medium}
      />
      <path
        d="M5.31959 19.299C5.31959 18.9346 5.61499 18.6392 5.97938 18.6392H32.3711C32.7355 18.6392 33.0309 18.9346 33.0309 19.299V53.6082C33.0309 53.9726 32.7355 54.268 32.3711 54.268H5.97938C5.61499 54.268 5.31959 53.9726 5.31959 53.6082V19.299Z"
        fill="white"
      />
      <path
        d="M36.3299 19.299C36.3299 18.9346 36.6253 18.6392 36.9897 18.6392H64.701C65.0654 18.6392 65.3608 18.9346 65.3608 19.299V25.8969C65.3608 26.2613 65.0654 26.5567 64.701 26.5567H36.9897C36.6253 26.5567 36.3299 26.2613 36.3299 25.8969V19.299Z"
        fill="white"
      />
      <path
        d="M36.3299 28.5361C36.3299 28.1717 36.6253 27.8763 36.9897 27.8763H64.701C65.0654 27.8763 65.3608 28.1717 65.3608 28.5361V35.134C65.3608 35.4984 65.0654 35.7938 64.701 35.7938H36.9897C36.6253 35.7938 36.3299 35.4984 36.3299 35.134V28.5361Z"
        fill="white"
      />
      <path
        d="M36.3299 37.7732C36.3299 37.4088 36.6253 37.1134 36.9897 37.1134H64.701C65.0654 37.1134 65.3608 37.4088 65.3608 37.7732V44.3711C65.3608 44.7355 65.0654 45.0309 64.701 45.0309H36.9897C36.6253 45.0309 36.3299 44.7355 36.3299 44.3711V37.7732Z"
        fill="white"
      />
      <path
        d="M36.3299 47.0103C36.3299 46.6459 36.6253 46.3505 36.9897 46.3505H64.701C65.0654 46.3505 65.3608 46.6459 65.3608 47.0103V53.6082C65.3608 53.9726 65.0654 54.268 64.701 54.268H36.9897C36.6253 54.268 36.3299 53.9726 36.3299 53.6082V47.0103Z"
        fill="white"
      />
      <path
        d="M7.29897 31.1753C7.29897 30.8109 7.59437 30.5155 7.95876 30.5155C8.32316 30.5155 8.61856 30.8109 8.61856 31.1753V37.7732C8.61856 38.1376 8.32316 38.433 7.95876 38.433C7.59437 38.433 7.29897 38.1376 7.29897 37.7732V31.1753Z"
        fill={color || colors.brand.black}
      />
      <path
        d="M54.1443 19.9588C54.5087 19.9588 54.8041 20.2542 54.8041 20.6186C54.8041 20.983 54.5087 21.2784 54.1443 21.2784L47.5464 21.2784C47.182 21.2784 46.8866 20.983 46.8866 20.6186C46.8866 20.2542 47.182 19.9588 47.5464 19.9588L54.1443 19.9588Z"
        fill={color || colors.brand.black}
      />
      <path
        d="M54.1443 29.1959C54.5087 29.1959 54.8041 29.4913 54.8041 29.8557C54.8041 30.2201 54.5087 30.5155 54.1443 30.5155H47.5464C47.182 30.5155 46.8866 30.2201 46.8866 29.8557C46.8866 29.4913 47.182 29.1959 47.5464 29.1959H54.1443Z"
        fill={color || colors.brand.black}
      />
      <path
        d="M54.1443 38.433C54.5087 38.433 54.8041 38.7284 54.8041 39.0928C54.8041 39.4572 54.5087 39.7526 54.1443 39.7526H47.5464C47.182 39.7526 46.8866 39.4572 46.8866 39.0928C46.8866 38.7284 47.182 38.433 47.5464 38.433H54.1443Z"
        fill={color || colors.brand.black}
      />
      <path
        d="M54.1443 47.6701C54.5087 47.6701 54.8041 47.9655 54.8041 48.3299C54.8041 48.6943 54.5087 48.9897 54.1443 48.9897H47.5464C47.182 48.9897 46.8866 48.6943 46.8866 48.3299C46.8866 47.9655 47.182 47.6701 47.5464 47.6701H54.1443Z"
        fill={color || colors.brand.black}
      />
    </svg>
  );
};
