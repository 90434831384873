import React from 'react';
import colors from '../../../utils/colors';
import { CustomIconProps } from './types';

export const PetOther = ({ color, size }: CustomIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M61.0295 13.9693C54.401 7.34331 43.6545 7.34331 37.0255 13.9708L36.0003 14.9979L34.9752 13.9708C28.3466 7.34327 17.599 7.34327 10.9724 13.9708C4.34286 20.6004 4.34286 31.3458 10.9704 37.9774L34.285 61.2881L34.4253 61.4299C34.8424 61.811 35.3909 62.0498 35.9984 62.0498C36.6041 62.0498 37.1482 61.815 37.5632 61.4393L37.7228 61.2778L61.0285 37.9733C67.657 31.3463 67.6571 20.5984 61.0295 13.9693Z"
        fill={color || colors.brand.gray.light}
      />
      <path
        d="M36.7218 26.3077C34.6864 26.3077 33.2568 26.8408 32.2391 27.495C31.6576 28.2947 31.2699 29.9181 31.4153 31.0085C32.651 30.0635 34.2503 29.482 35.6799 29.482C37.158 29.482 37.5941 30.112 37.5941 30.742C37.5941 32.632 33.4749 33.1166 33.4749 36.5816C33.4749 37.7204 34.0564 38.6654 34.8803 39.2712C35.898 39.1985 36.9641 38.7139 37.5941 37.9627C37.1822 37.5508 37.061 37.26 37.061 36.7027C37.061 34.7643 41.7134 34.3281 41.7134 30.2331C41.7134 27.592 39.896 26.3077 36.7218 26.3077ZM36.213 40.2889C35.0014 40.2889 34.1049 41.0885 34.1049 42.3727C34.1049 43.7781 34.856 44.5293 36.213 44.5293C37.4487 44.5293 38.2968 43.7539 38.2968 42.3485C38.2968 41.04 37.4487 40.2889 36.213 40.2889Z"
        fill={color ? colors.brand.white : colors.brand.gray.dark}
      />
    </svg>
  );
};
