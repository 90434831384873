import * as React from 'react';
import type { SingleIconProps } from '../types';
const SvgStrollerActive = (props: SingleIconProps) => (
  <svg
    width={props.size}
    height={props.size}
    viewBox="0 0 48 48"
    fill="none"
    aria-label={props.accessibilityLabel}
    className={'flex-shrink-0 ' + props.className}
  >
    <path
      d="M6.65552 18.9H36.4555V23.4889C36.4555 27.3549 33.3215 30.4889 29.4555 30.4889H13.6555C9.78953 30.4889 6.65552 27.3549 6.65552 23.4889V18.9Z"
      fill="#E4E5E9"
    />
    <rect
      x={5}
      y={18.0723}
      width={33.1111}
      height={2.48333}
      rx={1.24167}
      fill="#737684"
    />
    <path
      d="M20.7277 6.48328H18.2444C11.844 6.48328 6.65552 11.6718 6.65552 18.0722H20.7277V6.48328Z"
      fill="#E4E5E9"
    />
    <path
      d="M32.7096 11.45C32.5576 11.45 32.4138 11.5191 32.3189 11.6379L30.6611 13.7136L9.96667 39.6244L11.9071 41.1742L33.6638 13.9333H41.25C41.8023 13.9333 42.25 13.4856 42.25 12.9333V12.45C42.25 11.8977 41.8023 11.45 41.25 11.45H32.7096Z"
      fill="#BEBFC4"
    />
    <rect
      x={21.5555}
      y={28.2054}
      width={2.48333}
      height={15.1939}
      transform="rotate(-48.349 21.5555 28.2054)"
      fill="#BEBFC4"
    />
    <circle cx={21.9695} cy={26.7639} r={2.06944} fill="#737684" />
    <circle cx={10.3805} cy={41.6639} r={4.55278} fill={props.color} />
    <circle cx={10.3805} cy={41.6638} r={1.70729} fill={props.color} />
    <circle cx={35.6278} cy={39.5944} r={6.62222} fill={props.color} />
    <circle cx={35.6277} cy={39.5944} r={2.48333} fill={props.color} />
    <path
      d="M30.8774 4.54278C30.1534 3.81905 28.9796 3.81905 28.2555 4.54294L28.1435 4.65513L28.0316 4.54294C27.3075 3.81904 26.1336 3.81904 25.4098 4.54294C24.6857 5.26707 24.6857 6.44075 25.4096 7.16509L27.9562 9.71124L27.9715 9.72673C28.017 9.76836 28.077 9.79444 28.1433 9.79444C28.2095 9.79444 28.2689 9.76879 28.3142 9.72775L28.3317 9.71011L30.8773 7.16465C31.6013 6.44081 31.6013 5.26685 30.8774 4.54278Z"
      fill={props.color}
    />
  </svg>
);
export default SvgStrollerActive;
