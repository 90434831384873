import * as React from 'react';
import type { SingleIconProps } from '../types';
const SvgFurnitureAssemblyActive = (props: SingleIconProps) => (
  <svg
    width={props.size}
    height={props.size}
    viewBox="0 0 48 48"
    fill="none"
    aria-label={props.accessibilityLabel}
    className={'flex-shrink-0 ' + props.className}
  >
    <rect x={14.5} y={12.75} width={30} height={24} fill="#F7F8FA" />
    <rect x={15.25} y={14.25} width={13.5} height={6} fill="#E4E5E9" />
    <rect x={15.25} y={21.75} width={13.5} height={6} fill="#E4E5E9" />
    <rect x={15.25} y={29.25} width={13.5} height={6} fill="#E4E5E9" />
    <rect x={30.25} y={14.25} width={13.5} height={6} fill="#E4E5E9" />
    <rect x={30.25} y={21.75} width={13.5} height={6} fill="#E4E5E9" />
    <rect x={30.25} y={29.25} width={13.5} height={6} fill="#E4E5E9" />
    <rect x={13} y={10.5} width={33} height={3} rx={0.5} fill="#BEBFC4" />
    <rect x={13} y={36} width={33} height={3} rx={0.5} fill="#BEBFC4" />
    <rect
      x={19}
      y={15.75}
      width={6}
      height={0.75}
      rx={0.375}
      fill={props.color}
    />
    <rect
      x={19}
      y={23.25}
      width={6}
      height={0.75}
      rx={0.375}
      fill={props.color}
    />
    <rect
      x={19}
      y={30.75}
      width={6}
      height={0.75}
      rx={0.375}
      fill={props.color}
    />
    <rect
      x={34}
      y={15.75}
      width={6}
      height={0.75}
      rx={0.375}
      fill={props.color}
    />
    <rect
      x={34}
      y={23.25}
      width={6}
      height={0.75}
      rx={0.375}
      fill={props.color}
    />
    <rect
      x={34}
      y={30.75}
      width={6}
      height={0.75}
      rx={0.375}
      fill={props.color}
    />
    <path
      d="M4.25 21.75H7.25V38.25L6.125 41.25H5.375L4.25 38.25V21.75Z"
      fill="#E4E5E9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.75 6C2.33579 6 2 6.33579 2 6.75V18.5557C2 18.6831 2.03248 18.8085 2.09438 18.9199L2.83333 20.25H2.75C2.33579 20.25 2 20.5858 2 21C2 21.4142 2.33579 21.75 2.75 21.75H8.75C9.16421 21.75 9.5 21.4142 9.5 21C9.5 20.5858 9.16421 20.25 8.75 20.25H8.66667L9.40562 18.9199C9.46752 18.8085 9.5 18.6831 9.5 18.5557V6.75C9.5 6.33579 9.16421 6 8.75 6H2.75Z"
      fill={props.color}
    />
    <rect
      x={4.25}
      y={7.5}
      width={0.75}
      height={11.25}
      rx={0.375}
      fill="#E4E5E9"
    />
    <rect
      x={6.5}
      y={7.5}
      width={0.75}
      height={11.25}
      rx={0.375}
      fill="#E4E5E9"
    />
  </svg>
);
export default SvgFurnitureAssemblyActive;
