import * as React from 'react';
import type { SingleIconProps } from '../types';
const SvgReceipt = (props: SingleIconProps) => (
  <svg
    width={props.size}
    height={props.size}
    viewBox="0 0 20 20"
    fill="none"
    aria-label={props.accessibilityLabel}
    className={'flex-shrink-0 ' + props.className}
  >
    <path
      d="M5.625 8.125C5.625 7.95924 5.69085 7.80027 5.80806 7.68306C5.92527 7.56585 6.08424 7.5 6.25 7.5H13.75C13.9158 7.5 14.0747 7.56585 14.1919 7.68306C14.3092 7.80027 14.375 7.95924 14.375 8.125C14.375 8.29076 14.3092 8.44973 14.1919 8.56694C14.0747 8.68415 13.9158 8.75 13.75 8.75H6.25C6.08424 8.75 5.92527 8.68415 5.80806 8.56694C5.69085 8.44973 5.625 8.29076 5.625 8.125ZM6.25 11.25H13.75C13.9158 11.25 14.0747 11.1842 14.1919 11.0669C14.3092 10.9497 14.375 10.7908 14.375 10.625C14.375 10.4592 14.3092 10.3003 14.1919 10.1831C14.0747 10.0658 13.9158 10 13.75 10H6.25C6.08424 10 5.92527 10.0658 5.80806 10.1831C5.69085 10.3003 5.625 10.4592 5.625 10.625C5.625 10.7908 5.69085 10.9497 5.80806 11.0669C5.92527 11.1842 6.08424 11.25 6.25 11.25ZM18.125 4.375V16.25C18.1249 16.3565 18.0977 16.4613 18.0457 16.5543C17.9938 16.6473 17.919 16.7255 17.8284 16.7815C17.7377 16.8374 17.6343 16.8693 17.5279 16.874C17.4214 16.8788 17.3156 16.8563 17.2203 16.8086L15 15.6984L12.7797 16.8086C12.6929 16.852 12.5971 16.8747 12.5 16.8747C12.4029 16.8747 12.3071 16.852 12.2203 16.8086L10 15.6984L7.77969 16.8086C7.69286 16.852 7.5971 16.8747 7.5 16.8747C7.4029 16.8747 7.30714 16.852 7.22031 16.8086L5 15.6984L2.77969 16.8086C2.68442 16.8563 2.57856 16.8788 2.47214 16.874C2.36572 16.8693 2.26228 16.8374 2.17165 16.7815C2.08101 16.7255 2.00618 16.6473 1.95426 16.5543C1.90234 16.4613 1.87506 16.3565 1.875 16.25V4.375C1.875 4.04348 2.0067 3.72554 2.24112 3.49112C2.47554 3.2567 2.79348 3.125 3.125 3.125H16.875C17.2065 3.125 17.5245 3.2567 17.7589 3.49112C17.9933 3.72554 18.125 4.04348 18.125 4.375ZM16.875 4.375H3.125V15.2391L4.72031 14.4406C4.80714 14.3972 4.9029 14.3746 5 14.3746C5.0971 14.3746 5.19286 14.3972 5.27969 14.4406L7.5 15.5516L9.72031 14.4406C9.80714 14.3972 9.9029 14.3746 10 14.3746C10.0971 14.3746 10.1929 14.3972 10.2797 14.4406L12.5 15.5516L14.7203 14.4406C14.8071 14.3972 14.9029 14.3746 15 14.3746C15.0971 14.3746 15.1929 14.3972 15.2797 14.4406L16.875 15.2391V4.375Z"
      fill={props.color}
    />
  </svg>
);
export default SvgReceipt;
