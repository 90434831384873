import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import * as FullStory from '@fullstory/browser';
import { useRouter } from 'next/router';
import { FaSpinner } from 'react-icons/fa';
import Tile from '@/components/ui/Tile';
import { gql } from 'src/__generated__';
import { useQuery } from '@apollo/client';
import { updateLocalTimezoneFromApi } from '@/utils/updateLocalTimezoneFromApi';
import Mixpanel from '@/utils/mixpanel';
import { getTimezone } from '@/utils/helper';
import { clearCurrentTokens } from '@/utils/createFetcher';

const USER_BOUNDARY_QUERY = gql(/* GraphQL */ `
  query UserBoundary {
    me {
      ... on Customer {
        id
        rawId
        email
        locations {
          id
          zipcode {
            id
            timezone
          }
        }
      }
    }
  }
`);

export default function UserBoundary({ children }: PropsWithChildren) {
  const router = useRouter();
  const [delayedLoadingIndicator, setDelayedLoadingIndicator] = useState(false);
  const [longLoading, setLongLoading] = useState(false);
  const { data, loading, error } = useQuery(USER_BOUNDARY_QUERY, {
    fetchPolicy: 'network-only',
  });
  const customer =
    data?.me?.__typename === 'Customer' && data.me?.email ? data.me : undefined;
  const tz = useMemo(
    () => customer?.locations?.[0]?.zipcode?.timezone,
    [customer],
  );
  useEffect(() => {
    if (!tz) return;
    updateLocalTimezoneFromApi({
      apiTz: tz,
      customerId: customer?.rawId,
    });
  }, [tz, customer?.rawId]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDelayedLoadingIndicator(true);
    }, 1000);
    const longTimer = setTimeout(() => {
      setLongLoading(true);
    }, 5000);
    return () => {
      clearTimeout(timer);
      clearTimeout(longTimer);
    };
  }, []);

  useEffect(() => {
    if (!loading) setDelayedLoadingIndicator(false);
  }, [loading]);

  useEffect(() => {
    // setup user-specific tracking w/ fullstory
    if (customer?.email && FullStory.isInitialized()) {
      FullStory.identify(customer.email, { email: customer.email });
    }

    if (customer?.rawId) {
      Mixpanel.identify('customer_' + customer.rawId);
    }
  }, [customer]);

  // redirect logged-out users
  useEffect(() => {
    if (!router.isReady || loading || data?.me?.__typename === 'Customer') {
      return;
    }
    clearCurrentTokens();
    router.push({ pathname: '/sign-in' });
  }, [loading, router, data?.me?.__typename, data]);

  if (loading && delayedLoadingIndicator) {
    return (
      <Tile
        className="flex justify-center items-center h-[400px] flex-col"
        aria-busy="true"
        aria-live="polite"
      >
        {longLoading ? (
          <p className="mb-4 text-center">
            Loading is taking a bit of time,
            <br />
            but sit tight!
          </p>
        ) : null}
        <FaSpinner
          className="animate-spin h-6 w-6"
          aria-label="loading spinner"
        />
      </Tile>
    );
  }

  // for protected pages, if we're still loading user data, we don't want to show
  // anything. This is usually not seen or very quick
  if (loading || error) {
    return (
      <Tile
        className="flex justify-center items-center h-[400px] flex-col"
        aria-busy="true"
        aria-live="polite"
      ></Tile>
    );
  }

  if (data?.me?.__typename === 'Customer')
    return <React.Fragment key={getTimezone()}>{children}</React.Fragment>;

  return (
    <Tile
      className="flex justify-center items-center h-[400px] flex-col"
      aria-busy="true"
      aria-live="polite"
    ></Tile>
  );
}
