import * as React from 'react';
import type { SingleIconProps } from '../types';
const SvgCarWashActive = (props: SingleIconProps) => (
  <svg
    width={props.size}
    height={props.size}
    viewBox="0 0 48 48"
    fill="none"
    aria-label={props.accessibilityLabel}
    className={'flex-shrink-0 ' + props.className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0616 26.3586C13.3644 24.3255 16.1194 23.087 18.6051 23.087H29.395C31.8806 23.087 34.6356 24.3255 35.9384 26.3586L37.5536 28.8795L38.0121 28.7034C38.0649 28.6831 38.1214 28.6727 38.1783 28.6727H40.1053C40.5994 28.6727 41 29.0574 41 29.532V30.821C41 31.0583 40.7997 31.2507 40.5526 31.2507H39.0729L40.1373 32.9119C40.7013 33.7922 41 34.8056 41 35.8393V39.844C41 40.7932 40.1988 41.5627 39.2105 41.5627V44.1407C39.2105 44.6153 38.81 45 38.3158 45H35.6316C35.1374 45 34.7368 44.6153 34.7368 44.1407V41.5627H13.2632V44.1407C13.2632 44.6153 12.8626 45 12.3684 45H9.68421C9.19005 45 8.78947 44.6153 8.78947 44.1407V41.5627C7.80119 41.5627 7 40.7932 7 39.844V35.8393C7 34.8056 7.29866 33.7922 7.86271 32.9119L8.92713 31.2507H7.44737C7.20029 31.2507 7 31.0583 7 30.821V29.532C7 29.0574 7.40057 28.6727 7.89474 28.6727H9.82173C9.87864 28.6727 9.93505 28.6831 9.98788 28.7034L10.4464 28.8795L12.0616 26.3586ZM13.2938 27.2065C14.4677 25.4475 16.9945 24.376 19.2343 24.376H28.8686C31.1084 24.376 33.6352 25.4475 34.8091 27.2065L37.1144 31.0054C37.2919 31.298 37.0617 31.6637 36.7092 31.6515C26.9223 31.3107 21.1657 31.3215 11.3926 31.6524C11.0403 31.6643 10.8105 31.2988 10.988 31.0063L13.2938 27.2065ZM39.2105 34.8102C39.2105 34.2905 38.7339 33.8897 38.1975 33.9584L33.2765 34.5886C32.832 34.6455 32.5 35.0096 32.5 35.4403V35.977C32.5 36.4516 32.9006 36.8363 33.3947 36.8363H38.3158C38.81 36.8363 39.2105 36.4516 39.2105 35.977V34.8102ZM9.80245 33.9584C9.2661 33.8897 8.78947 34.2905 8.78947 34.8102V35.977C8.78947 36.4516 9.19005 36.8363 9.68421 36.8363H14.6053C15.0994 36.8363 15.5 36.4516 15.5 35.977V35.4403C15.5 35.0096 15.168 34.6455 14.7235 34.5886L9.80245 33.9584Z"
      fill="#E4E5E9"
    />
    <path
      d="M38.1976 33.9584C38.7339 33.8897 39.2105 34.2905 39.2105 34.8102V35.977C39.2105 36.4516 38.81 36.8363 38.3158 36.8363H33.3947C32.9006 36.8363 32.5 36.4516 32.5 35.977V35.4403C32.5 35.0096 32.832 34.6455 33.2765 34.5886L38.1976 33.9584Z"
      fill="#BEBFC4"
    />
    <path
      d="M8.78943 34.8102C8.78943 34.2905 9.26605 33.8897 9.8024 33.9584L14.7235 34.5886C15.168 34.6455 15.5 35.0096 15.5 35.4403V35.977C15.5 36.4516 15.0994 36.8363 14.6052 36.8363H9.68417C9.19 36.8363 8.78943 36.4516 8.78943 35.977V34.8102Z"
      fill="#BEBFC4"
    />
    <rect
      x={12.6666}
      y={5.73914}
      width={22.6667}
      height={2.73913}
      rx={1}
      fill="#BEBFC4"
    />
    <path
      d="M15.2675 3.67404C15.4065 3.27069 15.7862 3 16.2128 3H31.7869C32.2135 3 32.5932 3.27069 32.7323 3.67403L33.4443 5.73913H14.5554L15.2675 3.67404Z"
      fill="#E4E5E9"
    />
    <rect
      width={1.88475}
      height={3.66072}
      rx={0.942374}
      transform="matrix(0.968048 0.250764 -0.267095 0.96367 18.6432 10.2236)"
      fill={props.color}
    />
    <rect
      width={1.88475}
      height={3.66072}
      rx={0.942374}
      transform="matrix(0.968048 0.250764 -0.267095 0.96367 17.1765 15.5152)"
      fill={props.color}
    />
    <path
      d="M23.0555 11.2174C23.0555 10.7131 23.4783 10.3043 23.9999 10.3043C24.5215 10.3043 24.9444 10.7131 24.9444 11.2174V13.0435C24.9444 13.5477 24.5215 13.9565 23.9999 13.9565C23.4783 13.9565 23.0555 13.5477 23.0555 13.0435V11.2174Z"
      fill={props.color}
    />
    <path
      d="M23.0555 16.6956C23.0555 16.1914 23.4783 15.7826 23.9999 15.7826C24.5215 15.7826 24.9444 16.1914 24.9444 16.6956V18.5217C24.9444 19.026 24.5215 19.4348 23.9999 19.4348C23.4783 19.4348 23.0555 19.026 23.0555 18.5217V16.6956Z"
      fill={props.color}
    />
    <path
      d="M23.0555 16.6956C23.0555 16.1914 23.4783 15.7826 23.9999 15.7826C24.5215 15.7826 24.9444 16.1914 24.9444 16.6956V18.5217C24.9444 19.026 24.5215 19.4348 23.9999 19.4348C23.4783 19.4348 23.0555 19.026 23.0555 18.5217V16.6956Z"
      fill={props.color}
    />
    <path
      d="M32.9339 11.9045C32.7217 11.4438 32.936 10.9041 33.4125 10.699C33.889 10.4939 34.4473 10.7011 34.6595 11.1617L35.4277 12.8299C35.6399 13.2906 35.4256 13.8303 34.9491 14.0354C34.4726 14.2405 33.9143 14.0333 33.7022 13.5727L32.9339 11.9045Z"
      fill={props.color}
    />
    <path
      d="M35.2387 16.9091C35.0266 16.4484 35.2409 15.9087 35.7174 15.7036C36.1939 15.4985 36.7522 15.7057 36.9643 16.1664L37.7326 17.8346C37.9447 18.2952 37.7304 18.835 37.2539 19.0401C36.7774 19.2452 36.2192 19.038 36.007 18.5773L35.2387 16.9091Z"
      fill={props.color}
    />
    <path
      d="M35.2387 16.9091C35.0266 16.4484 35.2409 15.9087 35.7174 15.7036C36.1939 15.4985 36.7522 15.7057 36.9643 16.1664L37.7326 17.8346C37.9447 18.2952 37.7304 18.835 37.2539 19.0401C36.7774 19.2452 36.2192 19.038 36.007 18.5773L35.2387 16.9091Z"
      fill={props.color}
    />
    <path
      d="M27.7765 11.5781C27.6415 11.0911 27.9405 10.5904 28.4444 10.4599C28.9482 10.3294 29.4661 10.6184 29.6011 11.1055L30.09 12.8694C30.225 13.3564 29.926 13.8571 29.4221 13.9876C28.9183 14.1181 28.4004 13.8291 28.2654 13.342L27.7765 11.5781Z"
      fill={props.color}
    />
    <path
      d="M29.2432 16.8697C29.1082 16.3826 29.4072 15.882 29.911 15.7515C30.4148 15.621 30.9327 15.91 31.0677 16.3971L31.5566 18.161C31.6916 18.648 31.3926 19.1487 30.8888 19.2792C30.3849 19.4097 29.8671 19.1207 29.7321 18.6336L29.2432 16.8697Z"
      fill={props.color}
    />
    <path
      d="M29.2432 16.8697C29.1082 16.3826 29.4072 15.882 29.911 15.7515C30.4148 15.621 30.9327 15.91 31.0677 16.3971L31.5566 18.161C31.6916 18.648 31.3926 19.1487 30.8888 19.2792C30.3849 19.4097 29.8671 19.1207 29.7321 18.6336L29.2432 16.8697Z"
      fill={props.color}
    />
    <path
      d="M18.3987 11.1863C18.5337 10.6992 19.0516 10.4101 19.5554 10.5406C20.0593 10.6711 20.3583 11.1718 20.2233 11.6589L19.7344 13.4227C19.5994 13.9098 19.0815 14.1989 18.5777 14.0684C18.0738 13.9379 17.7748 13.4372 17.9098 12.9501L18.3987 11.1863Z"
      fill={props.color}
    />
    <path
      d="M16.9321 16.4778C17.0671 15.9908 17.585 15.7017 18.0888 15.8322C18.5926 15.9627 18.8916 16.4634 18.7566 16.9505L18.2677 18.7143C18.1327 19.2014 17.6149 19.4905 17.111 19.36C16.6072 19.2294 16.3082 18.7288 16.4432 18.2417L16.9321 16.4778Z"
      fill={props.color}
    />
    <path
      d="M13.3403 11.2425C13.5525 10.7819 14.1107 10.5747 14.5872 10.7798C15.0638 10.9849 15.2781 11.5246 15.0659 11.9853L14.2976 13.6535C14.0855 14.1141 13.5272 14.3213 13.0507 14.1162C12.5742 13.9111 12.3599 13.3714 12.572 12.9107L13.3403 11.2425Z"
      fill={props.color}
    />
    <path
      d="M11.0355 16.2472C11.2476 15.7865 11.8059 15.5793 12.2824 15.7844C12.7589 15.9895 12.9732 16.5292 12.7611 16.9899L11.9928 18.6581C11.7806 19.1188 11.2223 19.326 10.7458 19.1209C10.2693 18.9158 10.055 18.376 10.2672 17.9154L11.0355 16.2472Z"
      fill={props.color}
    />
  </svg>
);
export default SvgCarWashActive;
