import React from 'react';
import colors from '../../../utils/colors';
import { CustomIconProps } from './types';

export const Fridge = ({
  size,
  color, //= colors.brand.gray.dark,
}: CustomIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 5.29269C14 4.57875 14.5788 4 15.2927 4H38.561C39.2749 4 39.8537 4.57875 39.8537 5.29269V24.683H14V5.29269Z"
        fill={colors.brand.gray.light}
      />
      <path
        d="M14 25.6525H39.8537V63.1403C39.8537 63.8543 39.2749 64.433 38.561 64.433H15.2927C14.5788 64.433 14 63.8543 14 63.1403V25.6525Z"
        fill={colors.brand.gray.light}
      />
      <path
        d="M40.5 25.6525L57.4817 24.0366L58.8673 66.6648C58.8945 67.5027 58.1293 68.1445 57.309 67.9717L41.5264 64.6491C40.9282 64.5232 40.5 63.9954 40.5 63.3841V25.6525Z"
        fill={colors.brand.gray.light}
      />
      <path
        d="M16.5854 29.5305C16.5854 28.8166 17.1641 28.2378 17.8781 28.2378H35.9756C36.6896 28.2378 37.2683 28.8166 37.2683 29.5305V60.555C37.2683 61.2689 36.6896 61.8477 35.9756 61.8477H17.8781C17.1641 61.8477 16.5854 61.2689 16.5854 60.555V29.5305Z"
        fill={color || colors.brand.black}
      />
      <path
        d="M16.5854 14.9878C16.5854 14.6309 16.8747 14.3415 17.2317 14.3415C17.5887 14.3415 17.8781 14.6309 17.8781 14.9878V21.4512C17.8781 21.8082 17.5887 22.0976 17.2317 22.0976C16.8747 22.0976 16.5854 21.8082 16.5854 21.4512V14.9878Z"
        fill={color || colors.brand.gray.dark}
      />
      <path
        d="M57.1293 24.0366V66.3738C57.1293 67.0713 56.6233 67.6208 56.0123 67.6626L57.5063 67.9654C58.2414 68.1144 58.9208 67.5049 58.9208 66.6966V24.0366H57.1293Z"
        fill={color || colors.brand.gray.dark}
      />
    </svg>
  );
};
