import * as React from 'react';
import type { SingleIconProps } from '../types';
const SvgX = (props: SingleIconProps) => (
  <svg
    width={props.size}
    height={props.size}
    viewBox="0 0 16 16"
    fill="none"
    aria-label={props.accessibilityLabel}
    className={'flex-shrink-0 ' + props.className}
  >
    <path
      d="M14.473 13.5267C14.5992 13.6519 14.6702 13.8222 14.6702 14C14.6702 14.1778 14.5992 14.3482 14.473 14.4733C14.3478 14.5995 14.1774 14.6705 13.9996 14.6705C13.8219 14.6705 13.6515 14.5995 13.5263 14.4733L7.99963 8.94001L2.47297 14.4733C2.34779 14.5995 2.17739 14.6705 1.99963 14.6705C1.82188 14.6705 1.65148 14.5995 1.5263 14.4733C1.40009 14.3482 1.3291 14.1778 1.3291 14C1.3291 13.8222 1.40009 13.6519 1.5263 13.5267L7.05963 8.00001L1.5263 2.47334C1.35719 2.30423 1.29115 2.05776 1.35305 1.82675C1.41495 1.59575 1.59538 1.41532 1.82638 1.35342C2.05738 1.29152 2.30386 1.35757 2.47297 1.52667L7.99963 7.06001L13.5263 1.52667C13.7877 1.26526 14.2116 1.26526 14.473 1.52667C14.7344 1.78809 14.7344 2.21193 14.473 2.47334L8.93963 8.00001L14.473 13.5267Z"
      fill={props.color}
    />
  </svg>
);
export default SvgX;
