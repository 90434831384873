import * as React from 'react';
import type { SingleIconProps } from '../types';
const SvgPaintingActive = (props: SingleIconProps) => (
  <svg
    width={props.size}
    height={props.size}
    viewBox="0 0 48 48"
    fill="none"
    aria-label={props.accessibilityLabel}
    className={'flex-shrink-0 ' + props.className}
  >
    <path
      d="M21.05 17.5C21.05 15.5947 22.5947 14.05 24.5 14.05C26.4054 14.05 27.9501 15.5947 27.9501 17.5V38.2001C27.9501 40.1054 26.4054 41.6501 24.5 41.6501C22.5947 41.6501 21.05 40.1054 21.05 38.2001V17.5Z"
      fill="#E4E5E9"
    />
    <path
      d="M19.0077 12.111C19.0705 12.3348 19.3878 12.3348 19.4506 12.111L21.1247 6.14596C21.1489 6.05965 21.2276 6 21.3173 6H24.8077C24.8974 6 24.976 6.05965 25.0003 6.14596L26.6744 12.111C26.7372 12.3348 27.0545 12.3348 27.1173 12.111L28.7914 6.14596C28.8156 6.05965 28.8943 6 28.984 6H33.7C34.9703 6 36 7.02975 36 8.3V23.25C36 24.5203 34.9703 25.55 33.7 25.55H15.3C14.0297 25.55 13 24.5203 13 23.25V8.3C13 7.02975 14.0297 6 15.3 6H17.141C17.2307 6 17.3094 6.05965 17.3336 6.14596L19.0077 12.111Z"
      fill={props.color}
    />
    <path
      d="M13 19.8H36V23.25C36 24.5203 34.9703 25.55 33.7 25.55H15.3C14.0297 25.55 13 24.5203 13 23.25V19.8Z"
      fill="#E4E5E9"
    />
  </svg>
);
export default SvgPaintingActive;
