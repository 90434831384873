import * as React from 'react';
import type { SingleIconProps } from '../types';
const SvgDryCleaningActive = (props: SingleIconProps) => (
  <svg
    width={props.size}
    height={props.size}
    viewBox="0 0 34 41"
    fill="none"
    aria-label={props.accessibilityLabel}
    className={'flex-shrink-0 ' + props.className}
  >
    <path
      d="M30.0393 4.3842C29.2038 3.38192 27.7682 2.8398 26.3892 2.54693C25.1255 2.01597 24.3443 2.1822 20.527 0.935425L16.1032 4.9196L12.8683 1.12667C10.7465 1.40068 7.49712 1.52254 4.76423 2.21283C0.91378 2.50905 0.617554 12.4805 0.617554 12.4805C2.38917 11.7558 4.27812 12.3189 6.19167 12.272L5.7943 40.0319C11.147 39.5727 20.5955 40.5865 26.4126 40.935L26.7443 14.289C29.223 13.8095 30.614 13.7178 33.0988 14.2147C33.0988 14.2147 32.0136 6.75449 30.0393 4.3842Z"
      fill="#E4E5E9"
    />
    <path
      d="M16.1182 4.91944L12.8687 1.12779L11.7466 1.24113L16.1182 6.58147L21.3505 1.17609L20.5267 0.934937L16.1182 4.91944Z"
      fill={props.color}
    />
    <path
      d="M0.611938 12.4836L0.671171 11.5158C2.84202 11.0263 4.03556 11.1007 6.16938 11.1589V12.2706C5.64489 12.2814 5.16836 12.2545 4.74902 12.227C2.34828 12.0696 1.62092 12.1478 0.611938 12.4836Z"
      fill={props.color}
    />
    <path
      d="M26.745 14.2881C29.7911 13.6639 30.9212 13.9481 33.1269 14.2459L32.891 13.0425C30.5172 12.7249 29.1189 12.7501 26.745 13.0425V14.2881Z"
      fill={props.color}
    />
  </svg>
);
export default SvgDryCleaningActive;
