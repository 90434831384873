import React from 'react';
import colors from '../../../utils/colors';
import { CustomIconProps } from './types';

export const Baseboards = ({
  size,
  color = colors.brand.gray.dark,
}: CustomIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M24.3529 33.3103H47.6471V39.931H24.3529V33.3103Z" fill={color} />
      <path
        d="M48.7059 33.3103L70.6765 36.6207L69.6177 39.931H48.7059V33.3103Z"
        fill={color}
      />
      <path d="M24.3529 30H47.6471V32.2069H24.3529V30Z" fill={color} />
      <path
        d="M48.7059 30L72 33.3103L70.9412 35.5172L48.7059 32.2069V30Z"
        fill={color}
      />
      <path
        d="M23.2941 33.3103L1.32353 36.6207L2.38235 39.931H23.2941V33.3103Z"
        fill={color}
      />
      <path
        d="M23.2941 30L0 33.3103L1.05882 35.5172L23.2941 32.2069V30Z"
        fill={color}
      />
    </svg>
  );
};
