import React from 'react';
import colors from '../../../utils/colors';
import { CustomIconProps } from './types';

export const Laundry = ({
  size,
  color, //= colors.brand.gray.dark,
}: CustomIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M55.5589 11.0762C54.3057 9.57282 52.1523 8.75964 50.0838 8.32033C48.1882 7.5239 47.0166 7.77323 41.2905 5.90308L34.6549 11.8793L29.8024 6.18995C26.6198 6.60096 21.7457 6.78375 17.6464 7.81918C11.8707 8.26352 11.4264 23.2208 11.4264 23.2208C14.0838 22.1336 16.9172 22.9783 19.7876 22.908L19.1915 64.5478C27.2205 63.859 41.3933 65.3797 50.119 65.9024L50.6166 25.9335C54.3345 25.2142 56.421 25.0767 60.1483 25.8221C60.1483 25.8221 58.5205 14.6317 55.5589 11.0762Z"
        fill={color || colors.brand.gray.dark}
      />
      <path
        d="M34.6774 11.8791L29.8032 6.19163L28.12 6.36163L34.6774 14.3721L42.5259 6.26408L41.2902 5.90234L34.6774 11.8791Z"
        fill={colors.brand.black}
      />
      <path
        d="M11.418 23.2254L11.5068 21.7736C14.7631 21.0394 16.5534 21.1511 19.7541 21.2383V22.9059C18.9674 22.9221 18.2526 22.8818 17.6236 22.8405C14.0225 22.6045 12.9314 22.7217 11.418 23.2254Z"
        fill={colors.brand.black}
      />
      <path
        d="M50.6175 25.932C55.1867 24.9957 56.8818 25.4219 60.1904 25.8687L59.8365 24.0636C56.2758 23.5871 54.1783 23.6249 50.6175 24.0636V25.932Z"
        fill={colors.brand.black}
      />
    </svg>
  );
};
