import * as React from 'react';
import type { SingleIconProps } from '../types';
const SvgCarDetailingActive = (props: SingleIconProps) => (
  <svg
    width={props.size}
    height={props.size}
    viewBox="0 0 48 48"
    fill="none"
    aria-label={props.accessibilityLabel}
    className={'flex-shrink-0 ' + props.className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.95484 17.0047C11.4875 14.4967 14.7287 12.9689 17.653 12.9689H30.347C33.2713 12.9689 36.5125 14.4967 38.0452 17.0047L39.9454 20.1144L40.4848 19.8971C40.547 19.8721 40.6134 19.8592 40.6803 19.8592H42.9474C43.5287 19.8592 44 20.3338 44 20.9192V22.5093C44 22.802 43.7644 23.0393 43.4737 23.0393H41.7328L42.9851 25.0885C43.6486 26.1745 44 27.4246 44 28.6997V33.6397C44 34.8106 43.0574 35.7598 41.8947 35.7598V38.9399C41.8947 39.5254 41.4235 40 40.8421 40H37.6842C37.1028 40 36.6316 39.5254 36.6316 38.9399V35.7598H11.3684V38.9399C11.3684 39.5254 10.8972 40 10.3158 40H7.15789C6.57653 40 6.10526 39.5254 6.10526 38.9399V35.7598C4.94258 35.7598 4 34.8106 4 33.6397V28.6997C4 27.4246 4.35137 26.1745 5.01495 25.0885L6.26721 23.0393H4.52632C4.23563 23.0393 4 22.802 4 22.5093V20.9192C4 20.3338 4.47126 19.8592 5.05263 19.8592H7.31968C7.38663 19.8592 7.453 19.8721 7.51516 19.8971L8.05458 20.1144L9.95484 17.0047ZM11.4044 18.0506C12.7855 15.8808 15.7582 14.559 18.3933 14.559H29.7277C32.3628 14.559 35.3356 15.8808 36.7166 18.0506L39.4287 22.7367C39.6376 23.0978 39.3667 23.5489 38.9521 23.5338C27.4379 23.1134 20.6656 23.1267 9.16774 23.5348C8.75332 23.5496 8.48295 23.0987 8.69174 22.7379L11.4044 18.0506ZM41.8947 27.4302C41.8947 26.7891 41.334 26.2947 40.703 26.3795L34.9135 27.1568C34.3906 27.2271 34 27.6763 34 28.2076V28.8696C34 29.455 34.4713 29.9296 35.0526 29.9296H40.8421C41.4235 29.9296 41.8947 29.455 41.8947 28.8696V27.4302ZM7.297 26.3795C6.666 26.2947 6.10526 26.7891 6.10526 27.4302V28.8696C6.10526 29.455 6.57653 29.9296 7.15789 29.9296H12.9474C13.5287 29.9296 14 29.455 14 28.8696V28.2076C14 27.6763 13.6094 27.2271 13.0865 27.1568L7.297 26.3795Z"
      fill="#E4E5E9"
    />
    <path
      d="M40.703 26.3795C41.334 26.2947 41.8947 26.7891 41.8947 27.4302V28.8696C41.8947 29.455 41.4235 29.9296 40.8421 29.9296H35.0526C34.4713 29.9296 34 29.455 34 28.8696V28.2076C34 27.6763 34.3906 27.2271 34.9135 27.1569L40.703 26.3795Z"
      fill="#BEBFC4"
    />
    <path
      d="M6.10522 27.4302C6.10522 26.7891 6.66596 26.2947 7.29696 26.3795L13.0864 27.1569C13.6094 27.2271 14 27.6763 14 28.2076V28.8696C14 29.455 13.5287 29.9296 12.9473 29.9296H7.15786C6.57649 29.9296 6.10522 29.455 6.10522 28.8696V27.4302Z"
      fill="#BEBFC4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.4637 8.82812C40.0202 8.82812 40.4892 10.1494 40.6341 11.9529C42.425 12.0991 43.7369 12.5711 43.7369 13.1316C43.7369 13.6914 42.425 14.1643 40.6341 14.3103C40.4889 16.1138 40.0202 17.435 39.4637 17.435C38.9077 17.435 38.4382 16.1138 38.2936 14.3103C36.5022 14.164 35.1902 13.6914 35.1902 13.1316C35.1902 12.5711 36.5022 12.0988 38.2936 11.9529C38.4382 10.1494 38.9075 8.82812 39.4637 8.82812Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.6028 16.1041C34.9356 16.1041 35.216 16.8941 35.3026 17.9725C36.3734 18.06 37.1579 18.3422 37.1579 18.6773C37.1579 19.0121 36.3734 19.2949 35.3026 19.3821C35.2158 20.4605 34.9355 21.2505 34.6028 21.2505C34.2704 21.2505 33.9896 20.4605 33.9031 19.3821C32.832 19.2947 32.0475 19.0121 32.0475 18.6773C32.0475 18.3422 32.832 18.0598 33.9031 17.9725C33.9896 16.8941 34.2702 16.1041 34.6028 16.1041Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.3119 8C32.6447 8 32.9251 8.79002 33.0117 9.86841C34.0826 9.95586 34.867 10.2381 34.867 10.5732C34.867 10.908 34.0826 11.1907 33.0117 11.278C32.9249 12.3564 32.6447 13.1464 32.3119 13.1464C31.9795 13.1464 31.6987 12.3564 31.6122 11.278C30.5411 11.1905 29.7566 10.908 29.7566 10.5732C29.7566 10.2381 30.5411 9.95568 31.6122 9.86841C31.6987 8.79002 31.9793 8 32.3119 8Z"
      fill={props.color}
    />
  </svg>
);
export default SvgCarDetailingActive;
