import * as React from 'react';
import type { SingleIconProps } from '../types';
const SvgPlus = (props: SingleIconProps) => (
  <svg
    width={props.size}
    height={props.size}
    viewBox="0 0 12 12"
    fill="none"
    aria-label={props.accessibilityLabel}
    className={'flex-shrink-0 ' + props.className}
  >
    <path
      d="M11 6C11 6.27614 10.7761 6.5 10.5 6.5H6.5V10.5C6.5 10.7761 6.27614 11 6 11C5.72386 11 5.5 10.7761 5.5 10.5V6.5H1.5C1.22386 6.5 1 6.27614 1 6C1 5.72386 1.22386 5.5 1.5 5.5H5.5V1.5C5.5 1.22386 5.72386 1 6 1C6.27614 1 6.5 1.22386 6.5 1.5V5.5H10.5C10.7761 5.5 11 5.72386 11 6Z"
      fill={props.color}
    />
  </svg>
);
export default SvgPlus;
