import * as Sentry from '@sentry/nextjs';

/* eslint-disable no-unused-vars */
export type ExecuteBatch<Result, Input> = (
  args: Input[],
) => (Result | Error)[] | Promise<(Result | Error)[]>;
export type Scheduler = (queue: unknown[], flush: () => void) => void;
export type Resolve<Result> = (value: Result | PromiseLike<Result>) => void;
export type Reject = (reason?: unknown) => void;
export type AddToBatch<Result, Input> = (
  input: Input,
  batch?: boolean,
) => Promise<Result>;
/* eslint-enable no-unused-vars */

/**
 *
 * A simple queue to store promise resolution and arguments to be passed to
 * a callback fn
 *
 */
export class Queue<Result, Input> {
  readonly args: Input[] = [];
  readonly resolvers: { resolve: Resolve<Result>; reject: Reject }[] = [];

  add(input: Input, resolve: Resolve<Result>, reject: Reject): void {
    this.args.push(input);
    this.resolvers.push({ resolve, reject });
  }

  reset(): {
    args: Input[];
    resolvers: { resolve: Resolve<Result>; reject: Reject }[];
  } {
    const args = this.args.splice(0);
    const resolvers = this.resolvers.splice(0);

    return { args, resolvers };
  }

  isEmpty(): boolean {
    return this.args.length === 0;
  }

  get length(): number {
    return this.args.length;
  }
}

/**
 * Flushes every given ms, regardless of the queue.
 */
export const createIntervalScheduler = (ms = 20): Scheduler => {
  return function intervalScheduler(queue, flush) {
    if (queue.length === 1) setInterval(flush, ms);
  };
};

/**
 * This is a simple implementation of a data loading pattern to allow batching
 * a function invocation along a set schedule. By default, we will batch based on
 * a 20ms time window
 *
 * @param callback The function which receives the batched calls, this should return a list of results | errors which
 * match the size of the input array
 *
 * @param scheduler An optional scheduler override to allow for things like time or volume based queues
 * @returns A function which expects a single Input and returns a single result
 */
export function batch<Result, Input>(
  callback: ExecuteBatch<Result, Input>,
  scheduler: Scheduler,
): AddToBatch<Result, Input> {
  const queue = new Queue<Result, Input>();

  const flush = () => {
    if (queue.isEmpty()) return;

    const { args, resolvers } = queue.reset();

    Promise.resolve(callback(args))
      .then((results) => {
        if (args.length !== results.length) {
          throw new Error('Results must match length of arguments');
        }
        results.forEach((result, index) => {
          const resolver = resolvers[index];
          if (!resolver) {
            throw new Error(`Unable to find resolver for callback ${index}`);
          }

          const { resolve, reject } = resolver;

          if (result instanceof Error) {
            reject(result);
          } else {
            resolve(result);
          }
        });
      })
      .catch((e) => {
        const rejections = resolvers.map(({ reject }) => reject);
        rejections.forEach((reject) => reject(e));
      });
  };

  return (input: Input, batch = true) => {
    return new Promise<Result>((resolve, reject) => {
      if (batch) {
        queue.add(input, resolve, reject);
        scheduler(queue.args, flush);
        return;
      }

      // single input should result in single output
      Promise.resolve(callback([input]))
        .then((results) => {
          const result = results[0];
          if (!result) {
            reject(new Error('No result found'));
            return;
          }
          if (result instanceof Error) {
            reject(result);
          } else {
            resolve(result);
          }
        })
        .catch(() => {
          Sentry.captureException('Batching Failed');
        });
    });
  };
}
