import React, { useCallback } from 'react';
import { useContext, createContext } from 'react';
import * as Sentry from '@sentry/nextjs';
import { makeLogger } from '../utils/makeLogger';
import { useBrandData } from './useBrandData';
import { useApolloClient, useMutation } from '@apollo/client';
import { useRouter } from 'next/router';
import toast from '../utils/toast';
import Mixpanel from '@/utils/mixpanel';
import { gql } from 'src/__generated__';

const log = makeLogger('useAuth');

const PAGE_LOAD_MUTATION = gql(/* GraphQL */ `
  mutation UseAuthLogPageLoad($input: CreatePageLoadInput!) {
    createPageLoad(input: $input) {
      message
      success
    }
  }
`);

interface LoginResponse {
  access: string;
  refresh: string;
  path?: string;
}

interface AuthContext {
  logout: () => void;
  login: (_payload: {
    username: string;
    password: string;
  }) => Promise<LoginResponse | undefined>;
  getTokenFromHash: (_opts: { hash: string }) => {};
}

const authContext = createContext<AuthContext>({
  logout: () => {
    throw new Error('logout not initialized');
  },
  login: () => {
    throw new Error('login not initialized');
  },
  getTokenFromHash: () => {
    throw new Error('getTokenFromHash not initialized');
  },
});

// Provider component that wraps the app and makes auth object
// available to any child component that calls useAuth().
export function AuthProvider({ children }: { children: React.ReactNode }) {
  const auth = useAuthProvider();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object
// and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

/**
 * This is a reusable hook that tracks auth state. It lives at the top of the
 * react tree as the UseAuthProvider provider and allows any child components in
 * the tree to use the `useAuth` hook to access things like `user`.
 */
function useAuthProvider() {
  const { baseUrl } = useBrandData();
  const apolloClient = useApolloClient();
  const router = useRouter();
  const [logPageLoad] = useMutation(PAGE_LOAD_MUTATION);

  // fetch tokens from /api/token and save to localStorage
  const login = useCallback(
    async ({ username, password }: { username: string; password: string }) => {
      apolloClient.resetStore();

      const res = await fetch(baseUrl + '/api/token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username,
          password,
        }),
      });

      if (res.status === 401) {
        throw new Error('CREDENTIALS');
      }

      if (!res.ok) {
        throw new Error('ERR_NETWORK');
      }

      // save tokens from res json to localStorage
      const data: any = await res.json();
      log('login res', data);

      if (!data.access || !data.refresh) throw new Error('No login data');
      localStorage.setItem('access', data.access as string);
      localStorage.setItem('refresh', data.refresh as string);
      logPageLoad({ variables: { input: { path: 'c_password_sign_in' } } });

      return data;
    },
    [apolloClient, baseUrl, logPageLoad],
  );

  const logout = useCallback(() => {
    log('clear user identity & logout');
    localStorage.removeItem('refresh');
    localStorage.removeItem('access');
    localStorage.removeItem('timezone');
    localStorage.removeItem('isLinkGeneratedbyAgent');

    Sentry.setUser(null);
    Mixpanel.reset();
    apolloClient.resetStore();
  }, [apolloClient]);

  // get token from hash /api/auth_from_hash/${hash}
  const getTokenFromHash = useCallback(
    async ({ hash }: { hash: string }) => {
      try {
        const res = await fetch(baseUrl + `/api/auth_from_hash/${hash}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        // save tokens from res json to localStorage
        const data: any = await res.json();
        log('getTokenFromHash res', data);

        if (!data || !data.refresh || !data.access || !data.path) {
          throw new Error('No login data');
        }
        const isAdmin = data?.path === '/?isLinkGeneratedbyAgent=true';
        if (isAdmin) {
          localStorage.setItem('isLinkGeneratedbyAgent', 'true');
        }

        localStorage.setItem('access', data.access);
        localStorage.setItem('refresh', data.refresh);
        log('getTokenFromHash isAdmin', isAdmin);
        logPageLoad({
          variables: { input: { path: 'c_autologin' + data?.path, isAdmin } },
        });

        router.push(data.path as string);
      } catch (e) {
        logout();
        toast.error(
          "We couldn't log you in with that link. It may be expired or invalid. Use a newer link or use your email and password to sign in",
          { duration: 10000 },
        );
      }
    },
    [baseUrl, logPageLoad, logout, router],
  );

  return {
    logout,
    login,
    getTokenFromHash,
  };
}
