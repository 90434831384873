import * as Sentry from '@sentry/nextjs';

interface AuthUser {
  access?: string;
  refresh?: string;
}

function getCurrentTokens(): AuthUser | undefined {
  if (typeof localStorage === 'undefined') return;
  const access = localStorage.getItem('access');
  const refresh = localStorage.getItem('refresh');
  if (!access || !refresh) return;
  return { access, refresh };
}

function setCurrentTokens({ access, refresh }: AuthUser): void {
  if (typeof localStorage === 'undefined') return;
  if (access) localStorage.setItem('access', access);
  if (refresh) localStorage.setItem('refresh', refresh);
}

export function clearCurrentTokens(): void {
  if (typeof localStorage === 'undefined') return;
  localStorage.removeItem('access');
  localStorage.removeItem('refresh');
}

let onBadToken: () => void;
export function setOnBadToken(onBadTokenFn: () => void) {
  onBadToken = onBadTokenFn;
}

// const APOLLO_CLIENT_NAME_HEADER = 'apollographql-client-name';
// const APLLO_CLIENT_VERSION_HEADER_KEY = 'apollographql-client-version';

export function createFetcher(baseUrl?: string) {
  if (!baseUrl) throw Error('No base url set for fetcher');

  async function refreshAccessToken(user?: AuthUser): Promise<AuthUser | void> {
    if (!user) return;
    return fetch(`${baseUrl}/token/refresh/`, {
      body: JSON.stringify({ refresh: user.refresh }),
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${user.access}`,
        'user-agent': `HomeaglowCustomer-Web`,
      },
    })
      .then((res) => res.clone().json())
      .then(async ({ access }) => {
        if (!access) {
          await clearCurrentTokens();
          if (onBadToken) await onBadToken();
          return;
        }
        await setCurrentTokens({ access: access as string });
        return getCurrentTokens();
      })
      .catch((e) => {
        Sentry.captureException(e);
      });
  }

  return async function fetcher(
    input: RequestInfo | URL,
    init: RequestInit = {},
  ) {
    let user = await getCurrentTokens();
    let request: Request;
    if (typeof input === 'string') {
      request = new Request(`${baseUrl}${input}`, init);
    } else if (input instanceof URL) {
      request = new Request(`${baseUrl}${input.pathname}`, init);
    } else {
      request = input;
    }

    request.headers.set('content-type', 'application/json');

    if (user?.access)
      request.headers.set('Authorization', `Bearer ${user?.access}`);

    const cloned = request.clone();
    return fetch(request).then(async (response) => {
      if (response.status === 401) {
        const updatedUser = await refreshAccessToken(user);
        if (updatedUser) user = updatedUser;
        if (updatedUser?.access) {
          cloned.headers.set('Authorization', `Bearer ${updatedUser?.access}`);
        }

        try {
          // try original fetch again after things have been reset
          return fetch(cloned);
        } catch (e) {
          Sentry.captureException(e);
          await clearCurrentTokens();
          if (onBadToken) await onBadToken();
        }
      }
      return response;
    });
  };
}
