import * as React from 'react';
import type { SingleIconProps } from '../types';
const SvgHourglass = (props: SingleIconProps) => (
  <svg
    width={props.size}
    height={props.size}
    viewBox="0 0 41 40"
    fill="none"
    aria-label={props.accessibilityLabel}
    className={'flex-shrink-0 ' + props.className}
  >
    <path
      d="M31.75 11.8188V6.25C31.75 5.58696 31.4866 4.95107 31.0178 4.48223C30.5489 4.01339 29.913 3.75 29.25 3.75H11.75C11.087 3.75 10.4511 4.01339 9.98223 4.48223C9.51339 4.95107 9.25 5.58696 9.25 6.25V11.875C9.25084 12.263 9.34158 12.6455 9.51508 12.9925C9.68858 13.3395 9.94014 13.6415 10.25 13.875L18.4172 20L10.25 26.125C9.94014 26.3585 9.68858 26.6605 9.51508 27.0075C9.34158 27.3545 9.25084 27.737 9.25 28.125V33.75C9.25 34.413 9.51339 35.0489 9.98223 35.5178C10.4511 35.9866 11.087 36.25 11.75 36.25H29.25C29.913 36.25 30.5489 35.9866 31.0178 35.5178C31.4866 35.0489 31.75 34.413 31.75 33.75V28.1812C31.7491 27.7948 31.6591 27.4137 31.4871 27.0677C31.315 26.7216 31.0654 26.4199 30.7578 26.1859L22.5734 20L30.7578 13.8125C31.0656 13.579 31.3152 13.2776 31.4874 12.9318C31.6595 12.586 31.7493 12.205 31.75 11.8188ZM11.75 6.25H29.25V11.8188L28.3484 12.5H12.5828L11.75 11.875V6.25ZM20.5 18.4375L15.9172 15H25.0422L20.5 18.4375ZM29.25 33.75H11.75V28.125L19.25 22.5V26.25C19.25 26.5815 19.3817 26.8995 19.6161 27.1339C19.8505 27.3683 20.1685 27.5 20.5 27.5C20.8315 27.5 21.1495 27.3683 21.3839 27.1339C21.6183 26.8995 21.75 26.5815 21.75 26.25V22.5125L29.25 28.1812V33.75Z"
      fill={props.color}
    />
  </svg>
);
export default SvgHourglass;
