import React from 'react';
import { CustomIconProps } from './types';

export const Mail = ({ size, color }: CustomIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.49 5.32C20.9312 4.49327 19.9979 3.9985 19 4H5C3.34315 4 2 5.34315 2 7V17C2 18.6569 3.34315 20 5 20H19C20.6569 20 22 18.6569 22 17V7C22.0013 6.40168 21.8237 5.81662 21.49 5.32ZM5 6H19C19.2872 6.00037 19.5604 6.12422 19.75 6.34L12 10.87L4.26 6.33C4.44906 6.12052 4.71782 6.00067 5 6ZM19 18C19.5523 18 20 17.5523 20 17V8.49L13 12.59C12.6962 12.7663 12.3513 12.8594 12 12.86C11.6496 12.8624 11.3048 12.7728 11 12.6L4 8.49V17C4 17.5523 4.44772 18 5 18H19Z"
        fill={color}
      />
    </svg>
  );
};
