import React from 'react';
import colors from '../../../utils/colors';
import { CustomIconProps } from './types';

export const CalendarWeekly = ({
  size,
  color, //= colors.brand.gray.dark,
}: CustomIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="12.75"
        y="17.55"
        width="46.5"
        height="41.7"
        rx="3.25"
        stroke={color || colors.brand.gray.dark}
        strokeWidth="1.5"
      />
      <path
        d="M12.75 19.4614C12.75 17.6665 14.2051 16.2114 16 16.2114H56C57.7949 16.2114 59.25 17.6665 59.25 19.4614V26.2499H12.75V19.4614Z"
        fill={color || colors.brand.gray.dark}
        stroke={color || colors.brand.gray.dark}
        strokeWidth="1.5"
      />
      <rect
        x="21.6002"
        y="12"
        width="7.2"
        height="4.61538"
        rx="1"
        fill={color || colors.brand.gray.dark}
      />
      <rect
        x="43.1999"
        y="12"
        width="7.2"
        height="4.61538"
        rx="1"
        fill={color || colors.brand.gray.dark}
      />
      <rect
        x="18"
        y="29.75"
        width="6"
        height="6"
        rx="1"
        fill={colors.brand.gray.dark}
      />
      <rect
        x="28"
        y="29.75"
        width="6"
        height="6"
        rx="1"
        fill={colors.brand.gray.dark}
      />
      <rect
        x="38"
        y="29.75"
        width="6"
        height="6"
        rx="1"
        fill={color || colors.brand.gray.dark}
      />
      <rect
        x="48"
        y="29.75"
        width="6"
        height="6"
        rx="1"
        fill={colors.brand.gray.dark}
      />
      <rect
        x="18"
        y="39.75"
        width="6"
        height="6"
        rx="1"
        fill={colors.brand.gray.dark}
      />
      <rect
        x="28"
        y="39.75"
        width="6"
        height="6"
        rx="1"
        fill={colors.brand.gray.dark}
      />
      <rect
        x="38"
        y="39.75"
        width="6"
        height="6"
        rx="1"
        fill={color || colors.brand.gray.dark}
      />
      <rect
        x="48"
        y="39.75"
        width="6"
        height="6"
        rx="1"
        fill={colors.brand.gray.dark}
      />
      <rect
        x="18"
        y="49.75"
        width="6"
        height="6"
        rx="1"
        fill={colors.brand.gray.dark}
      />
      <rect
        x="28"
        y="49.75"
        width="6"
        height="6"
        rx="1"
        fill={colors.brand.gray.dark}
      />
      <rect
        x="38"
        y="49.75"
        width="6"
        height="6"
        rx="1"
        fill={color || colors.brand.gray.dark}
      />
      <rect
        x="48"
        y="49.75"
        width="6"
        height="6"
        rx="1"
        fill={colors.brand.gray.dark}
      />
    </svg>
  );
};
