import React from 'react';
import colors from '../../../utils/colors';
import { CustomIconProps } from './types';

export const Oven = ({
  color = colors.brand.gray.dark,
  size,
}: CustomIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12 7H61V51.7719H12V7Z" fill={colors.brand.gray.light} />
      <path
        d="M16.0833 16.1579C16.0833 15.5959 16.5404 15.1404 17.1042 15.1404H55.8958C56.4596 15.1404 56.9167 15.5959 56.9167 16.1579V46.6842C56.9167 47.2462 56.4596 47.7018 55.8958 47.7018H17.1042C16.5404 47.7018 16.0833 47.2462 16.0833 46.6842V16.1579Z"
        fill={color || colors.brand.black}
      />
      <path d="M12 52.7895H61V65H12V52.7895Z" fill={colors.brand.gray.light} />
      <path
        d="M24.25 61.9474C24.25 61.3854 24.707 60.9298 25.2708 60.9298H47.7292C48.293 60.9298 48.75 61.3854 48.75 61.9474C48.75 62.5093 48.293 62.9649 47.7292 62.9649H25.2708C24.707 62.9649 24.25 62.5093 24.25 61.9474Z"
        fill={colors.brand.gray.dark}
      />
      <path
        d="M24.25 18.193C24.25 17.631 24.707 17.1754 25.2708 17.1754H47.7292C48.293 17.1754 48.75 17.631 48.75 18.193C48.75 18.755 48.293 19.2105 47.7292 19.2105H25.2708C24.707 19.2105 24.25 18.755 24.25 18.193Z"
        fill={colors.brand.gray.dark}
      />
      <path
        d="M32.4167 9.03509H40.5833V13.1053H32.4167V9.03509Z"
        fill={colors.brand.gray.dark}
      />
      <path
        d="M20.1667 11.0702C20.1667 12.1941 19.2526 13.1053 18.125 13.1053C16.9974 13.1053 16.0833 12.1941 16.0833 11.0702C16.0833 9.94623 16.9974 9.03509 18.125 9.03509C19.2526 9.03509 20.1667 9.94623 20.1667 11.0702Z"
        fill={color || colors.brand.black}
      />
      <path
        d="M48.75 11.0702C48.75 12.1941 47.8359 13.1053 46.7083 13.1053C45.5808 13.1053 44.6667 12.1941 44.6667 11.0702C44.6667 9.94623 45.5808 9.03509 46.7083 9.03509C47.8359 9.03509 48.75 9.94623 48.75 11.0702Z"
        fill={color || colors.brand.black}
      />
      <path
        d="M28.3333 11.0702C28.3333 12.1941 27.4192 13.1053 26.2917 13.1053C25.1641 13.1053 24.25 12.1941 24.25 11.0702C24.25 9.94623 25.1641 9.03509 26.2917 9.03509C27.4192 9.03509 28.3333 9.94623 28.3333 11.0702Z"
        fill={color || colors.brand.black}
      />
      <path
        d="M56.9167 11.0702C56.9167 12.1941 56.0026 13.1053 54.875 13.1053C53.7474 13.1053 52.8333 12.1941 52.8333 11.0702C52.8333 9.94623 53.7474 9.03509 54.875 9.03509C56.0026 9.03509 56.9167 9.94623 56.9167 11.0702Z"
        fill={color || colors.brand.black}
      />
    </svg>
  );
};
