import * as React from 'react';
import type { SingleIconProps } from '../types';
const SvgCourierActive = (props: SingleIconProps) => (
  <svg
    width={props.size}
    height={props.size}
    viewBox="0 0 48 48"
    fill="none"
    aria-label={props.accessibilityLabel}
    className={'flex-shrink-0 ' + props.className}
  >
    <rect x={26} y={17} width={20.25} height={20.25} rx={1} fill="#E4E5E9" />
    <path
      d="M33.9553 17H39.0178V24.2321L36.4866 22.7857L33.9553 24.2321V17Z"
      fill={props.color}
    />
    <path
      d="M14.75 13.25H31.25"
      stroke={props.color}
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <path
      d="M11 17.75H21.5"
      stroke={props.color}
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <path
      d="M15.5 27.5L22.25 27.5"
      stroke={props.color}
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <path
      d="M4.25 32.75L22.25 32.75"
      stroke={props.color}
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <path
      d="M2 23L19.25 23"
      stroke={props.color}
      strokeWidth={1.5}
      strokeLinecap="round"
    />
  </svg>
);
export default SvgCourierActive;
