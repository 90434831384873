import React from 'react';

/**
 * This is meant to replace content when an error occurs.
 * Used in conjunction with Sentry's error boundary component
 */
const ErrorFallback = () => {
  return (
    <div className="flex flex-col flex-1 justify-center items-center h-full w-full p-4 bg-white lg:min-h-[500px]">
      <h2 className="text-xl">An Error Occurred</h2>

      <p className="text-center">Try going back or reloading the page.</p>
      <p className="text-center">Our team has been notified of this error.</p>
    </div>
  );
};

export default ErrorFallback;
