import { Toaster } from 'react-hot-toast';
import colors from '@/utils/colors';
import {
  FaCheckCircle,
  FaExclamationCircle,
  FaInfoCircle,
} from 'react-icons/fa';

export default function ToastProvider() {
  return (
    <Toaster
      position="top-center"
      reverseOrder={false}
      gutter={8}
      containerClassName=""
      containerStyle={{
        marginTop: 36,
      }}
      toastOptions={{
        // Define default options
        className: '',
        duration: 5000,
        style: {
          background: colors.brand.purple,
          color: '#fff',
        },
        icon: <FaInfoCircle className="fill-white flex-shrink-0" />,

        // Default options for specific types
        success: {
          style: {
            background: colors.brand.green,
            color: colors.brand.black,
          },
          icon: <FaCheckCircle className="fill-black flex-shrink-0" />,
        },
        error: {
          style: {
            background: colors.brand.darkerRed,
            color: colors.brand.white,
          },
          icon: <FaExclamationCircle className="fill-white flex-shrink-0" />,
        },
      }}
    />
  );
}
