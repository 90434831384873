import React from 'react';
import colors from '../../../utils/colors';
import { CustomIconProps } from './types';

export const PetCat = ({
  color = colors.brand.gray.dark,
  size,
}: CustomIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M66.6973 51.5196C66.6973 51.5196 65.4265 45.5256 62.7841 42.372C57.0745 35.5536 56.3725 35.9856 58.4857 26.2692C58.7917 24.8616 58.8457 23.1588 57.7153 22.2408C56.2141 21.024 53.8957 22.1832 52.7041 23.6916C50.2561 26.7948 50.9869 30.9744 52.1281 34.7364C53.0209 37.6776 55.1701 41.2056 56.9701 43.1532C61.5709 48.132 61.4773 56.6496 59.9041 59.6628C59.0761 61.2468 56.9773 61.578 55.0801 61.9092C55.1593 61.8048 55.1845 61.7076 55.2097 61.614C56.5309 54.4608 53.6905 47.2536 50.9041 40.5216C48.4849 34.6824 46.1665 29.3292 37.0477 25.0236C35.2549 24.1776 28.7425 22.6728 28.2529 22.4748C27.7129 22.2552 10.7281 24.9732 11.0485 25.344C7.26848 29.9952 4.35968 37.8144 7.71848 42.768C9.27728 45.0684 11.6713 46.6668 13.7377 48.546C15.8041 50.4252 15.8545 52.0236 16.9345 55.1664C17.6041 57.5532 17.6077 59.832 17.7229 62.2764C16.4629 63.5364 14.1553 63.4824 13.6981 65.9124C13.4137 67.428 14.6305 67.878 15.4549 68.1336C16.2793 68.3892 17.3413 67.4964 18.1549 67.2084C17.7913 67.8096 17.7553 69.0984 18.3997 69.3972C19.0441 69.696 20.7145 70.29 22.6657 69.8868C29.5669 68.454 36.3565 68.3892 42.2209 69.3108C43.1713 69.462 44.1109 70.146 45.0721 70.1856C46.0549 70.2252 46.8757 70.416 47.8549 70.2972C48.8665 70.1712 49.9033 70.0308 50.8501 69.6816C54.4213 69.4224 58.0933 67.9392 61.0489 66.132C62.3701 65.3256 63.5977 64.3464 64.5697 63.1548C67.1653 59.9652 67.2229 55.8324 66.6973 51.5196Z"
        fill={colors.brand.gray.light}
      />
      <path
        d="M10.8181 25.5384C12.2077 27.5184 15.1921 28.5516 17.2297 28.5516C21.3193 28.5516 21.9133 28.1088 25.6285 25.776C26.8129 25.0344 28.5301 23.6052 29.5525 22.8672C30.0349 23.0076 30.6541 23.1624 30.8953 23.2452C26.8813 27.612 24.7429 28.9548 20.0341 30.204C18.0613 30.726 14.0905 29.754 12.8665 29.2608C11.6425 28.7676 10.5373 27.8928 10.0081 26.7048C10.1269 26.478 10.3537 26.3556 10.8181 25.5384Z"
        fill={color}
      />
      <path
        d="M9.67682 12.114C9.55802 8.712 9.95402 5.1264 11.934 2.3292C12.0816 2.1204 12.258 1.9008 12.5064 1.8288C13.0428 1.674 13.4856 2.2392 13.7484 2.7216C15.0444 5.112 16.3116 7.0164 17.5464 9.43559C19.8036 8.89199 22.3128 9.98279 24.0732 11.4696C25.9236 9.1836 29.088 7.99559 32.022 8.4852C32.2632 10.1916 30.6828 12.3372 29.9952 13.9212C29.304 15.5052 28.6668 16.9848 28.548 18.0036C28.8576 20.1168 29.1708 21.132 29.4804 23.2416C29.5524 23.724 29.9844 25.1676 29.5956 25.4664C29.394 25.6212 27.4392 25.4988 27.1836 25.5312C26.4276 25.6176 28.6812 26.7264 27.9252 26.8164C27.018 26.9208 24.7212 26.8848 24.0372 27.216C20.6496 28.8468 21.4272 28.9008 18.2736 28.9008C17.4384 28.9008 13.3416 27.8928 12.726 27.342C12.1104 26.7912 11.5812 26.1396 10.9188 25.6428C10.008 24.9588 9.60482 24.21 8.62562 23.6268C8.19002 23.3676 6.37562 21.8772 5.91842 21.7764C5.42162 21.6684 7.44482 21.1932 7.16762 20.3724C7.02722 19.9548 4.77362 19.8216 5.07242 19.08C6.05522 16.6284 9.06482 12.2328 9.67682 12.114Z"
        fill={colors.brand.gray.light}
      />
      <path
        d="M13.6043 15.012C13.0031 15.1992 12.5783 15.822 12.6323 16.4376C12.6827 17.0028 13.0787 17.478 13.4999 17.8668C13.7771 18.126 14.0759 18.3708 14.4323 18.5004C14.7887 18.63 15.2135 18.6336 15.5303 18.4212C15.7499 18.2772 15.8975 18.0432 15.9947 17.802C16.3619 16.9128 16.0163 15.8112 15.1955 15.2892C14.6375 14.94 14.2055 14.8284 13.6043 15.012Z"
        fill={color}
      />
      <path
        d="M24.5233 18.3852C24.7789 18.9504 24.5809 19.6739 24.0733 20.0411C23.6089 20.3759 22.9825 20.3976 22.4065 20.3508C22.0285 20.3184 21.6397 20.2608 21.3049 20.0844C20.9701 19.908 20.6857 19.5948 20.6389 19.2276C20.6065 18.9684 20.6857 18.7092 20.8045 18.4752C21.2437 17.6184 22.3165 17.1432 23.2597 17.4024C23.8969 17.5788 24.2677 17.8236 24.5233 18.3852Z"
        fill={color}
      />
      <path
        opacity="0.6"
        d="M15.4368 21.8484C16.6068 21.618 17.8776 21.924 18.8064 22.6584C19.0188 22.824 19.2312 23.0652 19.1772 23.3244C19.1772 23.3244 17.0892 24.318 16.992 24.282C16.956 24.2676 16.9272 24.2316 16.9128 24.21C16.488 23.652 15.7716 22.698 15.4368 21.8484Z"
        fill={colors.brand.gray.dark}
      />
      <path
        opacity="0.6"
        d="M15.4368 21.8484C16.6068 21.618 17.8776 21.924 18.8064 22.6584C19.0188 22.824 19.2312 23.0652 19.1772 23.3244C19.1772 23.3244 17.0892 24.318 16.992 24.282C16.956 24.2676 16.9272 24.2316 16.9128 24.21C16.488 23.652 15.7716 22.698 15.4368 21.8484Z"
        fill={colors.brand.gray.dark}
      />
    </svg>
  );
};
