import * as React from 'react';
import type { SingleIconProps } from '../types';
const SvgWeedingActive = (props: SingleIconProps) => (
  <svg
    width={props.size}
    height={props.size}
    viewBox="0 0 48 48"
    fill="none"
    aria-label={props.accessibilityLabel}
    className={'flex-shrink-0 ' + props.className}
  >
    <path
      d="M29.4539 8C27.0817 20.589 23.3796 40.2361 23.3796 40.2361H33.4315C34.4339 27.6471 32.6448 20.589 29.4539 8Z"
      fill="#E4E5E9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.4278 40.2361H23.3795L29.4537 8C29.66 8.81375 29.8604 9.60438 30.0546 10.3749L24.4278 40.2361Z"
      fill={props.color}
    />
    <path
      d="M35.7438 18.3208C33.3717 26.8792 29.6695 40.236 29.6695 40.236H39.7214C40.7238 31.6776 38.9347 26.8792 35.7438 18.3208Z"
      fill="#E4E5E9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.7179 40.236H29.6695L35.7438 18.3208C35.95 18.874 36.1504 19.4115 36.3446 19.9353L30.7179 40.236Z"
      fill={props.color}
    />
    <path
      d="M19.5945 8C21.9666 20.589 25.6687 40.2361 25.6687 40.2361H15.6169C14.6145 27.6471 16.4035 20.589 19.5945 8Z"
      fill="#E4E5E9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.6204 40.2361H25.6687L19.5945 8C19.3882 8.81375 19.1878 9.60438 18.9937 10.3749L24.6204 40.2361Z"
      fill={props.color}
    />
    <path
      d="M24.7569 8C27.1291 20.589 30.8312 40.2361 30.8312 40.2361H20.7793C19.777 27.6471 21.566 20.589 24.7569 8Z"
      fill="#E4E5E9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.8312 40.2361H21.7706C20.8294 28.4153 22.3493 21.471 25.18 10.2454L24.7569 8C21.566 20.589 19.777 27.6471 20.7793 40.2361H30.8312L30.8312 40.2361Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.7828 40.2361H30.8311L24.7568 8C24.5506 8.81375 24.3502 9.60438 24.156 10.3749L29.7828 40.2361Z"
      fill={props.color}
    />
    <path
      d="M12.2562 18.9541C14.6283 27.2652 18.3305 40.236 18.3305 40.236H8.27859C7.27622 31.9249 9.06525 27.2652 12.2562 18.9541Z"
      fill="#E4E5E9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.2822 40.236H18.3305L12.2562 18.9541C12.2562 18.9541 12.2562 18.9541 12.2562 18.9541C12.05 19.4913 11.8496 20.0133 11.6554 20.522L17.2822 40.236Z"
      fill={props.color}
    />
  </svg>
);
export default SvgWeedingActive;
