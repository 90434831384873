import * as React from 'react';
import type { SingleIconProps } from '../types';
const SvgMowingActive = (props: SingleIconProps) => (
  <svg
    width={props.size}
    height={props.size}
    viewBox="0 0 48 48"
    fill="none"
    aria-label={props.accessibilityLabel}
    className={'flex-shrink-0 ' + props.className}
  >
    <path
      d="M11.9618 27.4625H30.401C36.4761 27.4625 41.401 32.3874 41.401 38.4625V39.808H11.9618V27.4625Z"
      fill="#E4E5E9"
    />
    <rect
      x={17.6598}
      y={25.5632}
      width={11.3958}
      height={1.8993}
      fill="#737684"
    />
    <path
      d="M16.7101 21.3152C16.7101 21.039 16.9339 20.8152 17.2101 20.8152H28.0052C29.1098 20.8152 30.0052 21.7106 30.0052 22.8152V25.0634C30.0052 25.3396 29.7814 25.5634 29.5052 25.5634H17.2101C16.9339 25.5634 16.7101 25.3396 16.7101 25.0634V21.3152Z"
      fill={props.color}
    />
    <path
      d="M4.02815 7.62116C3.65728 7.99202 3.65728 8.59331 4.02815 8.96417L7.56777 12.5038C7.92139 12.8574 8.1757 13.2979 8.30514 13.781L13.079 31.5972L16.7482 30.614L11.7109 11.8146C11.5814 11.3316 11.3271 10.8911 10.9735 10.5375L6.71417 6.27815C6.34331 5.90728 5.74202 5.90728 5.37116 6.27815L4.02815 7.62116Z"
      fill="#E4E5E9"
    />
    <path
      d="M5.31421 27.4625L10.5112 28.9474C11.3698 29.1927 11.9618 29.9775 11.9618 30.8704V38.8584H6.31421C5.76192 38.8584 5.31421 38.4106 5.31421 37.8584V27.4625Z"
      fill="#737684"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.4514 42.657C42.5494 42.657 44.2501 40.9563 44.2501 38.8584C44.2501 36.7605 42.5494 35.0598 40.4514 35.0598C38.3535 35.0598 36.6528 36.7605 36.6528 38.8584C36.6528 40.9563 38.3535 42.657 40.4514 42.657ZM40.4514 39.8081C40.9759 39.8081 41.4011 39.3829 41.4011 38.8584C41.4011 38.3339 40.9759 37.9088 40.4514 37.9088C39.927 37.9088 39.5018 38.3339 39.5018 38.8584C39.5018 39.3829 39.927 39.8081 40.4514 39.8081Z"
      fill={props.color}
    />
    <path
      d="M14.8108 31.7361H30.9549"
      stroke={props.color}
      strokeLinecap="round"
    />
    <path
      d="M15.7604 34.5851H26.2066"
      stroke={props.color}
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8612 42.6572C16.4836 42.6572 18.6094 40.5313 18.6094 37.9089C18.6094 35.2865 16.4836 33.1606 13.8612 33.1606C11.2388 33.1606 9.11292 35.2865 9.11292 37.9089C9.11292 40.5313 11.2388 42.6572 13.8612 42.6572ZM13.8612 38.8586C14.3857 38.8586 14.8108 38.4334 14.8108 37.9089C14.8108 37.3844 14.3857 36.9593 13.8612 36.9593C13.3367 36.9593 12.9115 37.3844 12.9115 37.9089C12.9115 38.4334 13.3367 38.8586 13.8612 38.8586Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.1345 24.6137L18.1345 21.7648L18.6094 21.7648L18.6094 24.6137L18.1345 24.6137Z"
      fill="#E4E5E9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.0338 24.6137L20.0338 21.7648L20.5087 21.7648L20.5087 24.6137L20.0338 24.6137Z"
      fill="#E4E5E9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.9331 24.6137L21.9331 21.7648L22.408 21.7648L22.408 24.6137L21.9331 24.6137Z"
      fill="#E4E5E9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.8324 24.6137L23.8324 21.7648L24.3073 21.7648L24.3073 24.6137L23.8324 24.6137Z"
      fill="#E4E5E9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.7317 24.6137L25.7317 21.7648L26.2065 21.7648L26.2065 24.6137L25.7317 24.6137Z"
      fill="#E4E5E9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.6311 24.6137L27.6311 21.7648L28.106 21.7648L28.106 24.6137L27.6311 24.6137Z"
      fill="#E4E5E9"
    />
  </svg>
);
export default SvgMowingActive;
