/**
 * This is a debug tool that will log a value (with a prefix for easy searching).
 *
 * It will always print in development by default, and can be used to debug
 * production builds as well by opening the developer tools and running the following:
 *
 * ```
 * localStorage.setItem('enable-logger', 'true')
 * ```
 *
 * To turn prod logs back off, run localStorage.removeItem('enable-logger'). Note, this
 * only affects your local browser and is meant to be used as a debug tool for bugs.
 *
 * This is a super simple implementation with only a single level of logs. We could
 * add to this later by using a logging library or extending this to distinguish
 * other levels like "debug, info, warning, error", etc.
 *
 * usage:
 * ```
 * const log = makeLogger('MyComponent')
 * log('v2cohort ', isInSpecialCohort)
 * // -> "[Login]: v2cohort true"
 * ```
 */

import { ENABLE_LOGGER_KEY } from './constants';

export const makeLogger = (label: string) => {
  if (typeof localStorage === 'undefined') return noop;
  const enabled = localStorage.getItem(ENABLE_LOGGER_KEY);
  if (process.env.NODE_ENV === 'development' || enabled) {
    return (...values: any[]) => log(label, ...values);
  }
  return noop;
};

const log = (label: string, ...values: any[]) => {
  // eslint-disable-next-line no-console
  console.log(`[${label}] `, ...values);
};
const noop = () => {};
