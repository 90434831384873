import * as React from 'react';
import type { SingleIconProps } from '../types';
const SvgPetSittingActive = (props: SingleIconProps) => (
  <svg
    width={props.size}
    height={props.size}
    viewBox="0 0 48 48"
    fill="none"
    aria-label={props.accessibilityLabel}
    className={'flex-shrink-0 ' + props.className}
  >
    <path d="M9.5542 21H37.5542V42H9.5542V21Z" fill="#E4E5E9" />
    <rect x={33.8042} y={12} width={4} height={6} rx={0.5} fill={props.color} />
    <path
      d="M23.2222 7.1867C23.5701 6.93777 24.0379 6.93777 24.3859 7.1867L41.188 19.2071C41.9789 19.773 41.5786 21.0204 40.6061 21.0204H7.00191C6.02945 21.0204 5.62916 19.773 6.42006 19.2071L23.2222 7.1867Z"
      fill="#E4E5E9"
    />
    <path d="M9.8042 21H37.8042V22H9.8042V21Z" fill="#E4E5E9" />
    <path
      d="M18.5737 26.7225C18.5076 24.8322 18.7277 22.8398 19.8279 21.2855C19.9099 21.1694 20.0079 21.0474 20.146 21.0074C20.444 20.9214 20.6901 21.2354 20.8361 21.5035C21.5562 22.8318 22.2604 23.89 22.9465 25.2342C24.2008 24.9322 25.5951 25.5383 26.5732 26.3645C27.6014 25.0942 29.3598 24.4341 30.9901 24.7061C31.1241 25.6543 30.246 26.8466 29.8639 27.7267C29.4798 28.6069 29.1258 29.4291 29.0597 29.9952C29.2318 31.1694 29.4058 31.7335 29.5778 32.9058C29.6179 33.1738 29.8579 33.976 29.6419 34.142C29.5298 34.228 28.4436 34.16 28.3016 34.178C27.8815 34.226 29.1338 34.8422 28.7137 34.8922C28.2096 34.9502 26.9333 34.9302 26.5532 35.1142C24.6709 36.0204 25.103 36.0504 23.3506 36.0504C22.8865 36.0504 20.6101 35.4903 20.268 35.1842C19.9259 34.8782 19.6319 34.5161 19.2638 34.24C18.7577 33.86 18.5336 33.4439 17.9895 33.1198C17.7475 32.9758 16.7393 32.1476 16.4852 32.0916C16.2092 32.0316 17.3334 31.7675 17.1794 31.3115C17.1014 31.0794 15.8491 31.0054 16.0151 30.5933C16.5613 29.231 18.2336 26.7886 18.5737 26.7225Z"
      fill="#fff"
    />
    <path
      d="M20.7563 28.3348C20.4225 28.4387 20.1867 28.7845 20.2167 29.1262C20.2447 29.44 20.4645 29.7038 20.6983 29.9197C20.8522 30.0636 21.0181 30.1995 21.216 30.2714C21.4138 30.3434 21.6497 30.3454 21.8255 30.2274C21.9475 30.1475 22.0294 30.0176 22.0834 29.8837C22.2872 29.39 22.0953 28.7785 21.6397 28.4887C21.3299 28.2928 21.0901 28.2308 20.7563 28.3348Z"
      fill={props.color}
    />
    <path
      d="M26.8218 30.209C26.9638 30.523 26.8538 30.9249 26.5719 31.1288C26.3139 31.3148 25.966 31.3268 25.6461 31.3008C25.4361 31.2828 25.2202 31.2508 25.0342 31.1528C24.8483 31.0548 24.6903 30.8809 24.6643 30.6769C24.6463 30.533 24.6903 30.389 24.7563 30.259C25.0003 29.7831 25.5961 29.5192 26.12 29.6632C26.4739 29.7591 26.6799 29.8951 26.8218 30.209Z"
      fill={props.color}
    />
    <path
      opacity={0.6}
      d="M21.7747 32.1318C22.4242 32.0039 23.1297 32.1738 23.6453 32.5815C23.7633 32.6734 23.8812 32.8073 23.8512 32.9512C23.8512 32.9512 22.692 33.5028 22.638 33.4828C22.6181 33.4748 22.6021 33.4548 22.5941 33.4429C22.3582 33.1351 21.9605 32.6035 21.7747 32.1318Z"
      fill="#737684"
    />
    <path
      opacity={0.6}
      d="M21.7747 32.1318C22.4242 32.0039 23.1297 32.1738 23.6453 32.5815C23.7633 32.6734 23.8812 32.8073 23.8512 32.9512C23.8512 32.9512 22.692 33.5028 22.638 33.4828C22.6181 33.4748 22.6021 33.4548 22.5941 33.4429C22.3582 33.1351 21.9605 32.6035 21.7747 32.1318Z"
      fill="#737684"
    />
  </svg>
);
export default SvgPetSittingActive;
