import React from 'react';
import { CustomIconProps } from './types';

export const Visa = ({
  size,
}: // color = colors.brand.gray.dark,
CustomIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 40 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1037_24508)">
        <path
          d="M37 0H3C1.34315 0 0 1.34315 0 3V22C0 23.6569 1.34315 25 3 25H37C38.6569 25 40 23.6569 40 22V3C40 1.34315 38.6569 0 37 0Z"
          fill="white"
          fillOpacity="0.01"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.2861 17.668L16.8401 8.49199H19.3561L17.8021 17.668H15.2861ZM26.7581 8.72999C26.0348 8.47023 25.2715 8.33991 24.5031 8.34499C22.0171 8.34499 20.2661 9.58999 20.2511 11.373C20.2371 12.691 21.5011 13.426 22.4551 13.865C23.4351 14.315 23.7651 14.602 23.7591 15.003C23.7531 15.619 22.9771 15.9 22.2541 15.9C21.2471 15.9 20.7121 15.76 19.8851 15.418L19.5611 15.272L19.2071 17.326C19.7951 17.582 20.8821 17.804 22.0111 17.816C24.6551 17.815 26.3731 16.586 26.3911 14.68C26.4011 13.637 25.7311 12.843 24.2791 12.188C23.3991 11.764 22.8611 11.481 22.8671 11.05C22.8671 10.669 23.3231 10.261 24.3071 10.261C24.9526 10.2467 25.594 10.3662 26.1911 10.612L26.4161 10.718L26.7581 8.72999ZM31.0471 8.49199C30.4451 8.49199 30.0091 8.67599 29.7441 9.27399L26.0161 17.668H28.6791L29.1971 16.263H32.3801L32.6751 17.668H35.0431L32.9721 8.49299H31.0481L31.0471 8.49199ZM29.9041 14.426C30.1121 13.898 30.9061 11.862 30.9061 11.862C30.8921 11.886 31.1131 11.331 31.2401 10.987L31.4101 11.777L31.9921 14.427H29.9041V14.426ZM10.6991 14.782L10.4771 13.524C10.0181 12.058 8.59307 10.472 6.99707 9.67599L9.21907 17.668H11.8831L15.8781 8.49199H13.2151L10.6991 14.782Z"
          fill="#293688"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.26099 8.49194V8.63994C7.41799 9.39994 9.60999 11.3199 10.477 13.5239L9.56499 9.27594C9.41299 8.69494 8.95099 8.51294 8.40499 8.49294H4.26199L4.26099 8.49194Z"
          fill="#F7981D"
        />
      </g>
      <defs>
        <clipPath id="clip0_1037_24508">
          <rect width="40" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
