import React from 'react';
import colors from '../../../utils/colors';
import { CustomIconProps } from './types';

export const CalendarStrict = ({ size, color }: CustomIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_1374_16524" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.9526 8.22815C24.9526 6.5713 26.2958 5.22815 27.9526 5.22815H65C66.6569 5.22815 68 6.5713 68 8.22815V14.7942H24.9526V8.22815ZM59.9286 10.0715C59.9286 11.5574 58.7241 12.762 57.2382 12.762C55.7523 12.762 54.5477 11.5574 54.5477 10.0715C54.5477 8.5856 55.7523 7.38104 57.2382 7.38104C58.7241 7.38104 59.9286 8.5856 59.9286 10.0715ZM35.7145 12.762C37.2004 12.762 38.4049 11.5574 38.4049 10.0715C38.4049 8.5856 37.2004 7.38104 35.7145 7.38104C34.2286 7.38104 33.024 8.5856 33.024 10.0715C33.024 11.5574 34.2286 12.762 35.7145 12.762Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.9526 8.22815C24.9526 6.5713 26.2958 5.22815 27.9526 5.22815H65C66.6569 5.22815 68 6.5713 68 8.22815V14.7942H24.9526V8.22815ZM59.9286 10.0715C59.9286 11.5574 58.7241 12.762 57.2382 12.762C55.7523 12.762 54.5477 11.5574 54.5477 10.0715C54.5477 8.5856 55.7523 7.38104 57.2382 7.38104C58.7241 7.38104 59.9286 8.5856 59.9286 10.0715ZM35.7145 12.762C37.2004 12.762 38.4049 11.5574 38.4049 10.0715C38.4049 8.5856 37.2004 7.38104 35.7145 7.38104C34.2286 7.38104 33.024 8.5856 33.024 10.0715C33.024 11.5574 34.2286 12.762 35.7145 12.762Z"
        fill={color ? colors.brand.gray.light : colors.brand.gray.dark}
      />
      <path
        d="M68 14.7942V16.2942H69.5V14.7942H68ZM24.9526 14.7942H23.4526V16.2942H24.9526V14.7942ZM27.9526 3.72815C25.4674 3.72815 23.4526 5.74287 23.4526 8.22815H26.4526C26.4526 7.39972 27.1242 6.72815 27.9526 6.72815V3.72815ZM65 3.72815H27.9526V6.72815H65V3.72815ZM69.5 8.22815C69.5 5.74287 67.4853 3.72815 65 3.72815V6.72815C65.8284 6.72815 66.5 7.39972 66.5 8.22815H69.5ZM69.5 14.7942V8.22815H66.5V14.7942H69.5ZM24.9526 16.2942H68V13.2942H24.9526V16.2942ZM23.4526 8.22815V14.7942H26.4526V8.22815H23.4526ZM57.2382 14.262C59.5525 14.262 61.4286 12.3858 61.4286 10.0715H58.4286C58.4286 10.729 57.8956 11.262 57.2382 11.262V14.262ZM53.0477 10.0715C53.0477 12.3858 54.9238 14.262 57.2382 14.262V11.262C56.5807 11.262 56.0477 10.729 56.0477 10.0715H53.0477ZM57.2382 5.88104C54.9238 5.88104 53.0477 7.75718 53.0477 10.0715H56.0477C56.0477 9.41403 56.5807 8.88104 57.2382 8.88104V5.88104ZM61.4286 10.0715C61.4286 7.75718 59.5525 5.88104 57.2382 5.88104V8.88104C57.8956 8.88104 58.4286 9.41403 58.4286 10.0715H61.4286ZM36.9049 10.0715C36.9049 10.729 36.372 11.262 35.7145 11.262V14.262C38.0288 14.262 39.9049 12.3858 39.9049 10.0715H36.9049ZM35.7145 8.88104C36.372 8.88104 36.9049 9.41403 36.9049 10.0715H39.9049C39.9049 7.75718 38.0288 5.88104 35.7145 5.88104V8.88104ZM34.524 10.0715C34.524 9.41403 35.057 8.88104 35.7145 8.88104V5.88104C33.4002 5.88104 31.524 7.75718 31.524 10.0715H34.524ZM35.7145 11.262C35.057 11.262 34.524 10.729 34.524 10.0715H31.524C31.524 12.3858 33.4002 14.262 35.7145 14.262V11.262Z"
        fill={color ? colors.brand.gray.light : colors.brand.gray.dark}
        mask="url(#path-1-inside-1_1374_16524)"
      />
      <path
        d="M25.3276 15.1547H67.625V42.0473C67.625 43.4971 66.4497 44.6723 65 44.6723H27.9526C26.5029 44.6723 25.3276 43.4971 25.3276 42.0473V15.1547Z"
        stroke={color ? colors.brand.gray.light : colors.brand.gray.dark}
        strokeWidth="0.75"
      />
      <rect
        x="57.0181"
        y="2.2124"
        width="0.42481"
        height="8.31919"
        rx="0.212405"
        stroke={color ? colors.brand.gray.light : colors.brand.gray.dark}
        strokeWidth="0.42481"
      />
      <rect
        x="35.4751"
        y="2.2124"
        width="0.42481"
        height="8.31919"
        rx="0.212405"
        stroke={color ? colors.brand.gray.light : colors.brand.gray.dark}
        strokeWidth="0.42481"
      />
      <rect
        x="31"
        y="19.9922"
        width="8"
        height="8"
        fill={color ? colors.brand.gray.light : colors.brand.gray.dark}
      />
      <rect
        x="43"
        y="19.9922"
        width="8"
        height="8"
        fill={color ? colors.brand.gray.light : colors.brand.gray.dark}
      />
      <rect
        x="55"
        y="19.9922"
        width="8"
        height="8"
        fill={color ? colors.brand.gray.light : colors.brand.gray.dark}
      />
      <rect
        x="31"
        y="31.0269"
        width="8"
        height="8"
        fill={color ? colors.brand.gray.light : colors.brand.gray.dark}
      />
      <rect
        x="43"
        y="31.0269"
        width="8"
        height="8"
        fill={color ? colors.brand.gray.light : colors.brand.gray.dark}
      />
      <rect
        x="55"
        y="31.0269"
        width="8"
        height="8"
        fill={color ? colors.brand.gray.light : colors.brand.gray.dark}
      />
      <path
        d="M51.5609 21.4078L47.4625 25.8214C47.4031 25.8855 47.3197 25.922 47.2323 25.9223C47.1485 25.9228 47.068 25.8899 47.0085 25.8309L45.4322 24.2546C45.3086 24.1309 45.3086 23.9305 45.4322 23.8069C45.5558 23.6833 45.7562 23.6833 45.8799 23.8069L47.2323 25.1531L51.1006 20.979C51.1748 20.8875 51.2938 20.845 51.4092 20.8688C51.5245 20.8926 51.617 20.9788 51.6489 21.0922C51.6808 21.2055 51.6469 21.3273 51.5609 21.4078Z"
        stroke={color || colors.brand.black}
      />
      <path
        d="M63.0607 32.0425L58.9623 36.4562C58.9029 36.5203 58.8196 36.5568 58.7322 36.5571C58.6484 36.5575 58.5679 36.5247 58.5084 36.4656L56.9321 34.8893C56.8085 34.7657 56.8085 34.5653 56.9321 34.4417C57.0557 34.318 57.2561 34.318 57.3797 34.4417L58.7322 35.7878L62.6005 31.6138C62.6747 31.5223 62.7937 31.4798 62.909 31.5036C63.0244 31.5274 63.1169 31.6135 63.1488 31.7269C63.1807 31.8403 63.1468 31.962 63.0607 32.0425Z"
        stroke={color || colors.brand.black}
      />
      <path
        d="M22.2501 67.4121H24.0287L24.8749 61.8528L22.2499 61.8529L22.2501 67.4121Z"
        fill={colors.brand.gray.light}
      />
      <path
        d="M22.2501 69.0001L27.5 69V68.95C27.5 68.748 27.4472 68.5479 27.3445 68.3613C27.2418 68.1747 27.0913 68.0051 26.9015 67.8623C26.7118 67.7194 26.4865 67.6061 26.2386 67.5288C25.9906 67.4515 25.7249 67.4117 25.4566 67.4117L22.25 67.4118L22.2501 69.0001Z"
        fill={color || colors.brand.black}
      />
      <path
        d="M4.16535 66.1499L5.7048 66.6174L8.54034 61.7481L6.26817 61.0581L4.16535 66.1499Z"
        fill={colors.brand.gray.light}
      />
      <path
        d="M3.29035 67.5119L8.39649 69L8.41936 68.9468C8.51181 68.732 8.55194 68.5044 8.53746 68.2769C8.52299 68.0493 8.45419 67.8264 8.335 67.6207C8.21581 67.4151 8.04858 67.2307 7.84282 67.0783C7.63707 66.9258 7.39683 66.8082 7.13583 66.7321L4.01706 65.8232L3.29035 67.5119Z"
        fill={color || colors.brand.black}
      />
      <path
        d="M22.4459 65.8233C22.4824 65.8233 22.5189 65.8202 22.5549 65.8142L24.5485 65.4805C24.6842 65.4574 24.8072 65.392 24.8967 65.2953C24.9862 65.1986 25.0369 65.0765 25.0402 64.9494C25.33 52.5926 23.9053 44.2888 20.8057 38.3369C20.7506 38.2315 20.6603 38.1452 20.5481 38.0905C20.4358 38.0359 20.3075 38.0157 20.182 38.033L13.2021 38.986C13.0712 39.0036 12.9503 39.0605 12.8582 39.1479C12.7661 39.2353 12.7081 39.3484 12.6931 39.4694L11.8718 44.6004C11.8683 44.6281 11.862 44.6554 11.8529 44.682L4.9794 63.6455C4.93252 63.7835 4.94614 63.9329 5.01736 64.0618C5.08859 64.1907 5.21175 64.2887 5.36051 64.335L7.21801 64.9052C7.35471 64.9465 7.50296 64.9414 7.63588 64.8907C7.7688 64.84 7.87759 64.7471 7.9425 64.6288L17.24 48.4855C17.2585 48.4516 17.2879 48.4237 17.3243 48.4058C17.3607 48.3879 17.4022 48.3807 17.4431 48.3854C17.484 48.3901 17.5224 48.4064 17.5528 48.432C17.5833 48.4576 17.6044 48.4912 17.6133 48.5283L21.8634 65.3902C21.8921 65.5128 21.9656 65.6227 22.0715 65.7015C22.1775 65.7803 22.3096 65.8233 22.4459 65.8233Z"
        fill={colors.brand.gray.dark}
      />
      <path
        d="M28.2434 27.9242C28.227 27.9918 28.2164 28.0606 28.2115 28.1298L22.4955 31.2371L21.1062 30.4834L19.625 32.3105L21.947 33.87C22.1346 33.9959 22.3605 34.0612 22.5908 34.0559C22.8211 34.0506 23.0433 33.9751 23.2242 33.8406L29.1184 29.459C29.3844 29.5518 29.674 29.5661 29.9489 29.4999C30.2237 29.4338 30.4706 29.2904 30.6568 29.0888C30.843 28.8872 30.9597 28.6369 30.9913 28.3713C31.0229 28.1057 30.9679 27.8373 30.8337 27.6019C30.6995 27.3664 30.4924 27.1751 30.2399 27.0532C29.9874 26.9314 29.7016 26.8849 29.4204 26.9199C29.1392 26.9549 28.876 27.0697 28.6657 27.2491C28.4555 27.4285 28.3081 27.664 28.2434 27.9242Z"
        fill={colors.brand.gray.light}
      />
      <path
        d="M22.2386 31.2725C22.2225 31.1971 22.1895 31.1259 22.142 31.0638C22.0945 31.0017 22.0335 30.9501 21.9632 30.9126L19.5078 29.601C19.1816 29.3605 18.7688 29.2533 18.36 29.3031C17.9512 29.3528 17.5797 29.5553 17.327 29.8663C17.0743 30.1773 16.961 30.5713 17.012 30.9619C17.0629 31.3526 17.2739 31.708 17.5988 31.9503L19.5565 33.8922C19.6123 33.9476 19.6801 33.9909 19.755 34.0191C19.83 34.0472 19.9104 34.0596 19.9908 34.0554C20.0712 34.0512 20.1498 34.0305 20.2211 33.9947C20.2924 33.9589 20.3547 33.9088 20.4039 33.8479L22.1332 31.7069C22.1825 31.646 22.2174 31.5756 22.2356 31.5007C22.2537 31.4257 22.2548 31.3479 22.2386 31.2725Z"
        fill={color || colors.brand.black}
      />
      <path
        d="M16.1249 27.7029C14.1919 27.7029 12.6249 26.4585 12.6249 24.9233C12.6249 23.3882 14.1919 22.1437 16.1249 22.1437C18.0579 22.1437 19.6249 23.3882 19.6249 24.9233C19.6249 26.4585 18.0579 27.7029 16.1249 27.7029Z"
        fill={colors.brand.gray.light}
      />
      <path
        d="M13.2612 39.6155C13.294 39.6155 13.3267 39.6131 13.359 39.6084L20.5008 38.5706C20.5815 38.5588 20.6586 38.5327 20.7276 38.4938C20.7966 38.4549 20.8559 38.404 20.9021 38.3442C20.9482 38.2843 20.9802 38.2168 20.9961 38.1457C21.0119 38.0746 21.0114 38.0014 20.9944 37.9305L20.647 36.4785C20.8248 36.2433 22.2731 34.1982 20.5092 32.2773C20.543 31.5948 20.275 30.9277 19.7622 30.4178C19.2494 29.9079 18.5324 29.5955 17.7637 29.5473L14.0729 29.2987C13.5981 29.2668 13.1216 29.3364 12.6835 29.5017C12.2454 29.667 11.8585 29.9232 11.5553 30.2487C11.2522 30.5742 11.0417 30.9594 10.9415 31.372C10.8413 31.7847 10.8544 32.2126 10.9797 32.6198L12.6717 39.1986C12.7024 39.3169 12.7773 39.4225 12.8844 39.4982C12.9914 39.5739 13.1243 39.6153 13.2612 39.6155Z"
        fill={color || colors.brand.black}
      />
      <path
        d="M5.90876 45.3828C5.77733 45.221 5.6886 45.0362 5.64882 44.8416C5.60905 44.647 5.61919 44.4473 5.67854 44.2564C5.7379 44.0656 5.84501 43.8883 5.99234 43.737C6.13967 43.5858 6.32364 43.4642 6.53128 43.381L10.9432 31.6736L13.5 32.7207L8.67265 44.12C8.81158 44.4304 8.81379 44.7739 8.67886 45.0856C8.54393 45.3972 8.28122 45.6553 7.94054 45.8109C7.59985 45.9666 7.20485 46.009 6.83038 45.9301C6.45591 45.8511 6.12799 45.6564 5.90876 45.3828Z"
        fill={colors.brand.gray.light}
      />
      <path
        d="M9.61138 33.9613L12.92 34.8251C13.0141 34.8497 13.1133 34.8565 13.2107 34.8449C13.3081 34.8334 13.4015 34.8039 13.4846 34.7583C13.5676 34.7128 13.6383 34.6523 13.6918 34.581C13.7454 34.5097 13.7805 34.4293 13.7949 34.3452L14.2971 31.3989C14.4396 30.982 14.385 30.5333 14.1453 30.1511C13.9057 29.7689 13.5005 29.4844 13.0187 29.3599C12.5368 29.2355 12.0174 29.2811 11.5745 29.487C11.1315 29.6928 10.8009 30.042 10.6553 30.4581L9.19023 33.143C9.14831 33.2199 9.12606 33.3037 9.12498 33.3887C9.12391 33.4736 9.14404 33.5578 9.18401 33.6355C9.22397 33.7132 9.28284 33.7825 9.35658 33.8387C9.43033 33.8949 9.51724 33.9367 9.61138 33.9613Z"
        fill={color || colors.brand.black}
      />
      <path
        d="M19.1981 25.0086C18.487 25.2594 17.5554 25.3158 16.8107 25.1711C16.066 25.0264 15.382 24.6867 14.84 24.1924C14.9177 24.3824 14.5727 25.4816 14.8007 26.145C15.0817 26.9626 16.3819 26.5721 17.9062 29.2504C19.0536 31.2666 19.7695 34.2192 19.7695 34.2192C19.7695 34.2192 14.5838 31.3426 14.1797 31.1137C13.3147 30.6239 12.0282 29.2769 11.6953 27.3872C11.1094 24.0612 13.5962 20.0624 15.3428 20.6539C15.8068 20.8109 16.1942 20.9958 16.3819 21.4186C16.7909 20.9518 17.5651 20.8505 18.1606 21.0903C18.7561 21.3301 19.1794 21.8497 19.4071 22.4125C19.5868 22.8667 19.8143 23.3518 19.7694 23.8337C19.7245 24.3156 19.566 24.7825 19.3049 25.2015L19.1981 25.0086Z"
        fill={color ? colors.brand.black : colors.brand.gray.dark}
      />
    </svg>
  );
};
