import * as React from 'react';
import type { SingleIconProps } from '../types';
const SvgOrganizingActive = (props: SingleIconProps) => (
  <svg
    width={props.size}
    height={props.size}
    viewBox="0 0 48 48"
    fill="none"
    aria-label={props.accessibilityLabel}
    className={'flex-shrink-0 ' + props.className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.38218 10C4.05327 10 3.78662 10.2682 3.78662 10.599V33.3594C3.78662 33.6901 4.05327 33.9583 4.38218 33.9583H27.0133C27.3422 33.9583 27.6089 33.6901 27.6089 33.3594V10.599C27.6089 10.2682 27.3422 10 27.0133 10H4.38218ZM13.3155 15.9896C12.9866 15.9896 12.72 16.2578 12.72 16.5885V18.9844C12.72 19.3151 12.9866 19.5833 13.3155 19.5833H18.08C18.4089 19.5833 18.6755 19.3151 18.6755 18.9844V16.5885C18.6755 16.2578 18.4089 15.9896 18.08 15.9896H13.3155Z"
      fill="#E4E5E9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.4623 23.776C20.1333 23.776 19.8667 23.9436 19.8667 24.1503V38.3756C19.8667 38.5823 20.1333 38.75 20.4623 38.75H43.0934C43.4223 38.75 43.6889 38.5823 43.6889 38.3756V24.1503C43.6889 23.9436 43.4223 23.776 43.0934 23.776H20.4623ZM29.3956 27.5195C29.0667 27.5195 28.8 27.6871 28.8 27.8938V29.3912C28.8 29.598 29.0667 29.7656 29.3956 29.7656H34.16C34.489 29.7656 34.7556 29.598 34.7556 29.3912V27.8938C34.7556 27.6871 34.489 27.5195 34.16 27.5195H29.3956Z"
      fill="#BEBFC4"
    />
    <path
      d="M2 10.599C2 10.2682 2.26664 10 2.59556 10H28.8C29.1289 10 29.3956 10.2682 29.3956 10.599V12.9948C29.3956 13.3256 29.1289 13.5938 28.8 13.5938H2.59556C2.26664 13.5938 2 13.3256 2 12.9948V10.599Z"
      fill={props.color}
    />
    <path
      d="M18.08 24.1753C18.08 23.9548 18.3466 23.776 18.6755 23.776H44.88C45.2089 23.776 45.4755 23.9548 45.4755 24.1753V25.7725C45.4755 25.9931 45.2089 26.1718 44.88 26.1718H18.6755C18.3466 26.1718 18.08 25.9931 18.08 25.7725V24.1753Z"
      fill={props.color}
    />
  </svg>
);
export default SvgOrganizingActive;
