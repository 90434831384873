import * as React from 'react';
import type { SingleIconProps } from '../types';
const SvgMeapPrepActive = (props: SingleIconProps) => (
  <svg
    width={props.size}
    height={props.size}
    viewBox="0 0 48 48"
    fill="none"
    aria-label={props.accessibilityLabel}
    className={'flex-shrink-0 ' + props.className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.4822 5.64679C22.406 5.02547 21.03 5.39419 20.4086 6.47035L6.90863 29.853C6.28731 30.9292 6.65603 32.3053 7.73219 32.9266L25.2692 43.0516C26.3454 43.6729 27.7214 43.3042 28.3428 42.228L41.8428 18.8453C42.4641 17.7692 42.0954 16.3931 41.0192 15.7718L23.4822 5.64679ZM27.1783 11.2449C26.4609 10.8307 25.5435 11.0765 25.1293 11.7939C24.7151 12.5113 24.9609 13.4287 25.6783 13.8429L32.823 17.9679C33.5405 18.3822 34.4579 18.1363 34.8721 17.4189C35.2863 16.7015 35.0405 15.7841 34.323 15.3699L27.1783 11.2449Z"
      fill="#E4E5E9"
    />
    <path
      d="M14.8489 24.3199C15.3351 18.1104 15.7173 13.2293 13.5798 5.69751L10.3372 27.9626L13.036 28.3556L13.3307 26.3316L14.2813 26.47C14.4968 26.5014 14.6937 26.3418 14.7099 26.1247C14.7558 25.5089 14.8028 24.9084 14.8489 24.3199Z"
      fill="#BEBFC4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7107 28.454L9.66248 27.8644L7.79548 40.6837C7.63267 41.8016 8.40691 42.8398 9.52479 43.0026C10.6427 43.1654 11.6809 42.3911 11.8437 41.2733L13.7107 28.454ZM9.72131 41.6532C10.0939 41.7074 10.44 41.4494 10.4943 41.0767C10.5485 40.7041 10.2905 40.358 9.91784 40.3038C9.54521 40.2495 9.19915 40.5076 9.14488 40.8802C9.09061 41.2528 9.34869 41.5989 9.72131 41.6532Z"
      fill={props.color}
    />
  </svg>
);
export default SvgMeapPrepActive;
