import {
  toast as reactHotToast,
  ToastOptions,
  DefaultToastOptions,
} from 'react-hot-toast';

export const UNKNOWN_ERROR =
  'Something went wrong. Please try again or contact our support team';

const toast = (message: string, opts?: ToastOptions) => {
  return reactHotToast(message, opts);
};
toast.error = (message: string, opts?: ToastOptions) => {
  return reactHotToast.error(message, opts);
};
toast.loading = (message: string, opts?: ToastOptions) => {
  return reactHotToast.loading(message, opts);
};
toast.success = (message: string, opts?: ToastOptions) => {
  return reactHotToast.success(message, opts);
};
toast.custom = (message: string, opts?: ToastOptions) => {
  return reactHotToast.custom(message, opts);
};
toast.dismiss = (toastId?: string) => {
  return reactHotToast.dismiss(toastId);
};
toast.remove = (toastId?: string) => {
  return reactHotToast.remove(toastId);
};
toast.promise = (
  promise: Promise<any>,
  msgs: any,
  opts?: DefaultToastOptions,
) => {
  return reactHotToast.promise(promise, msgs, opts);
};

export default toast;
