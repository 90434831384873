import * as React from 'react';
import type { SingleIconProps } from '../types';
const SvgHouse = (props: SingleIconProps) => (
  <svg
    width={props.size}
    height={props.size}
    viewBox="0 0 24 24"
    fill="none"
    aria-label={props.accessibilityLabel}
    className={'flex-shrink-0 ' + props.className}
  >
    <g id="House">
      <path
        id="Vector"
        d="M21 10.8328V19.5C21 19.8978 20.842 20.2793 20.5607 20.5607C20.2794 20.842 19.8978 21 19.5 21H15.75C15.3522 21 14.9706 20.842 14.6893 20.5607C14.408 20.2793 14.25 19.8978 14.25 19.5V15.75C14.25 15.5511 14.171 15.3603 14.0303 15.2197C13.8897 15.079 13.6989 15 13.5 15H10.5C10.3011 15 10.1103 15.079 9.96967 15.2197C9.82902 15.3603 9.75 15.5511 9.75 15.75V19.5C9.75 19.8978 9.59196 20.2793 9.31066 20.5607C9.02936 20.842 8.64782 21 8.25 21H4.5C4.10218 21 3.72064 20.842 3.43934 20.5607C3.15804 20.2793 3 19.8978 3 19.5V10.8328C2.99997 10.6252 3.04303 10.4199 3.12646 10.2298C3.20989 10.0397 3.33187 9.86895 3.48469 9.72843L10.9847 2.65218L10.995 2.64187C11.2711 2.39074 11.631 2.25159 12.0042 2.25159C12.3775 2.25159 12.7373 2.39074 13.0134 2.64187C13.0166 2.64554 13.0201 2.64898 13.0238 2.65218L20.5238 9.72843C20.675 9.86969 20.7954 10.0407 20.8774 10.2308C20.9594 10.4209 21.0011 10.6258 21 10.8328Z"
        fill={props.color}
      />
    </g>
  </svg>
);
export default SvgHouse;
