import React, { Fragment, useMemo } from 'react';
import BlockTitle from '@/components/ui/BlockTitle';
import { useRouter } from 'next/router';
import { useQuery } from '@apollo/client';
import { gql } from 'src/__generated__';
import { Gift } from '@/components/ui/HAIcons';
import { BsChatFill, BsFillQuestionSquareFill } from 'react-icons/bs';
import { AiFillSetting } from 'react-icons/ai';
import { FaCalendar } from 'react-icons/fa';
import { formatWithWholeDollar } from '@/utils/formatCurrency';
import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';
import Link from 'next/link';
import Icon from '@/components/ui/icon';
import colors from '@/utils/colors';

const CUSTOMER_NAME_QUERY = gql(/* GraphQL */ `
  query CustomerMenuDetails {
    me {
      ... on Customer {
        rawId
        id
        firstName
        cashBackProgram {
          cashOutDiscountRate
        }
        customerReferralTerms {
          hostBenefitCredit
        }
      }
    }
  }
`);

// const LAZY_UNREAD_MESSAGES_COUNT = gql(/* GraphQL */ `
//   query GetUnreadMessagesForCustomer {
//     me {
//       ... on Customer {
//         rawId
//         id
//         unreadMessageCount
//       }
//     }
//   }
// `);

interface SideDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

const SideDrawer = (props: SideDrawerProps) => {
  const { isOpen, onClose } = props;
  const router = useRouter();

  const { data } = useQuery(CUSTOMER_NAME_QUERY);

  const me = data?.me?.__typename === 'Customer' ? data.me : undefined;
  const firstName = useMemo(() => me?.firstName, [me?.firstName]);

  const referralValue = useMemo(
    () => me?.customerReferralTerms?.hostBenefitCredit,
    [me?.customerReferralTerms?.hostBenefitCredit],
  );

  // const { data: rawData } = useQuery(LAZY_UNREAD_MESSAGES_COUNT);
  // const hasUnreadMessages = useMemo(() => {
  //   const me = rawData?.me?.__typename === 'Customer' ? rawData.me : undefined;
  //   return !!me?.unreadMessageCount;
  // }, [rawData?.me]);

  const onClickItem = () => {
    onClose();
  };

  const routes: {
    [key: string]: {
      name: string;
      subtitle?: string;
      route: string;
      icon?: React.ReactNode;
      prefetch?: boolean;
    };
  } = useMemo(() => {
    return {
      UpcomingAppointments: {
        name: 'Appointments',
        route: '/',
        icon: <FaCalendar className="h-6 w-6 fill-black mr-4" />,
      },
      Chat: {
        name: 'Chat',
        route: '/chat',
        icon: (
          <div className="relative">
            <BsChatFill className="h-6 w-6 fill-black mr-4" />
            {/* {hasUnreadMessages && (
              <span className="w-3 h-3 absolute right-3 bg-red rounded-full top-0" />
            )} */}
          </div>
        ),
      },
      Settings: {
        name: 'Settings',
        route: '/settings',
        prefetch: true,
        icon: <AiFillSetting className="h-6 w-6 fill-black mr-4" />,
      },
      Help: {
        name: 'Help',
        route: '/help',
        icon: <BsFillQuestionSquareFill className="h-6 w-6 fill-black mr-4" />,
      },
      ...(referralValue
        ? {
            Refer: {
              name: `Earn ${formatWithWholeDollar(referralValue)} credit`,
              subtitle: 'Invite your friends',
              route: '/refer',
              icon: (
                <div className="mr-4">
                  <Gift size={24} color="black" />
                </div>
              ),
            },
          }
        : {}),
      ...(me?.cashBackProgram
        ? {
            Rewards: {
              name: `Earn ${(
                me.cashBackProgram.cashOutDiscountRate * 100
              ).toFixed(0)}% cash back`,
              subtitle: 'Get cash back on home-related services',
              route: '/rewards',
              icon: (
                <div className="mr-4">
                  <Icon
                    name="dollar-circle-fill"
                    size={30}
                    color={colors.brand.black}
                  />
                </div>
              ),
            },
          }
        : {}),
    };
  }, [referralValue, me?.cashBackProgram]);

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog onClose={onClose} className={'relative'}>
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-lightGray bg-opacity-80 z-[150]" />
        </Transition.Child>

        {/* Full-screen container to center the panel */}
        <div className="fixed inset-0 flex sm:p-2 lg:p-4 overflow-y-auto justify-center items-center z-[200]">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-200"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="ease-in duration-200"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <Dialog.Panel
              role="navigation"
              className={clsx(
                `absolute top-0 left-0 bottom-0`,
                `sm:w-[90%] lg:w-[400px] bg-white shadow-lg p-8`,
              )}
            >
              <BlockTitle text={`Hi ${firstName || ''}`} />
              <div className="w-full">
                <ul className="w-full">
                  {Object.keys(routes).map((navItem) => {
                    const item = routes[navItem];
                    // if the current route is the home page, we want to highlight the Upcoming Appointments link
                    // if the current route is not the home page, we want to highlight the link that matches the current route
                    // the includes() method is used to check if the current route includes the route of the link
                    // this is because the current route is dynamic and includes the id of the appointment
                    // so we can't just check if the current route is equal to the route of the link
                    // if the current link we're checking is the one for the home page, we have to do a special check
                    // because the home page route is just a slash, and the current route is always going to include a slash
                    const currentRouteActive =
                      router.pathname === '/'
                        ? item.route === '/'
                        : item.route !== '/'
                        ? router.pathname.includes(item.route)
                        : false;
                    return (
                      <li
                        key={`navItem-layout-${item.name}`}
                        className="w-full"
                      >
                        <Link
                          className={`flex flex-row hover:underline flex-1 justify-start items-center w-full text-lg py-4 ${
                            currentRouteActive ? 'font-bold' : 'font-normal'
                          }`}
                          href={item.route}
                          onClick={() => onClickItem()}
                          prefetch={item.prefetch}
                        >
                          {item.icon ? item.icon : null}
                          <div>
                            <p>{item.name}</p>

                            {item.subtitle ? (
                              <p className="text-sm">{item.subtitle}</p>
                            ) : null}
                          </div>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default SideDrawer;
