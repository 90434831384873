import App, { AppContext } from 'next/app';
import React, { useEffect } from 'react';
// import * as FullStory from '@fullstory/browser';
import * as Sentry from '@sentry/nextjs';
import Layout from '@/components/features/Layout';
import '../../globals.css';
import ErrorFallback from '@/components/ui/ErrorFallback';
import { AuthProvider } from 'src/hooks/useAuth';
import { LayoutControlsProvider } from 'src/hooks/useLayoutControls';
import localFont from 'next/font/local';
import ApolloProvider from 'src/data/ApolloProvider';
import { makeLogger } from '@/utils/makeLogger';
import { BrandDataProvider } from 'src/hooks/useBrandData';
import ToastProvider from '@/utils/ToastProvider';
import { useRouter } from 'next/router';
import { gql } from 'src/__generated__';
import { useMutation } from '@apollo/client';
import { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import colors from '@/utils/colors';
import { CookieBlocker } from '@/components/features/CookieBlocker';
import { ExperimentProvider } from '@/data/hooks/useExperiment';

const log = makeLogger('_app');

export const tisaSans = localFont({
  src: [
    {
      path: '../assets/fonts/TisaSansPro-Thin.otf',
      weight: '100',
      style: 'normal',
    },
    {
      path: '../assets/fonts/TisaSansPro-Light.otf',
      weight: '300',
      style: 'normal',
    },
    {
      path: '../assets/fonts/TisaSansPro.otf',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../assets/fonts/TisaSansPro-Medium.otf',
      weight: '500',
      style: 'normal',
    },
    {
      path: '../assets/fonts/TisaSansPro-Bold.otf',
      weight: '700',
      style: 'normal',
    },
    {
      path: '../assets/fonts/TisaSansPro-Extrabold.otf',
      weight: '800',
      style: 'normal',
    },
    {
      path: '../assets/fonts/TisaSansPro-Black.otf',
      weight: '900',
      style: 'normal',
    },
  ],
});

const PageLoadMuation = gql(/* GraphQL */ `
  mutation LogPageLoad($input: CreatePageLoadInput!) {
    createPageLoad(input: $input) {
      message
      success
    }
  }
`);

const PageLoadTracker = () => {
  const router = useRouter();
  const [logPageLoad] = useMutation(PageLoadMuation);

  useEffect(() => {
    const isLinkGeneratedbyAgent = localStorage.getItem(
      'isLinkGeneratedbyAgent',
    );
    const isAdmin = isLinkGeneratedbyAgent === 'true';
    log('pageview: ', router.asPath);
    log('isAdmin', isAdmin);
    logPageLoad({
      variables: { input: { path: router.asPath, isAdmin } },
    }).catch(Sentry.captureException);
  }, [logPageLoad, router.asPath]);

  return null;
};

export default function CustomerApp({ Component, pageProps, host }: any) {
  log('NEXT_PUBLIC_VERCEL_ENV', process.env.NEXT_PUBLIC_VERCEL_ENV);

  // useEffect(() => {
  //   if (Math.floor(Math.random() * 100) === 1) {
  //     FullStory.init({ orgId: 'o-1CXHQX-na1' });
  //   }
  // }, []);

  // Use the layout defined at the page level, if available
  const getLayout =
    Component.getLayout ||
    ((page: any, fonts: any) => (
      <Layout>
        <main className={fonts}>{page}</main>
      </Layout>
    ));

  return (
    <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
      <ToastProvider />
      <BrandDataProvider host={host as string | undefined}>
        <SkeletonTheme
          baseColor={colors.brand.gray.medium}
          highlightColor={colors.brand.gray.lightest}
        >
          <ApolloProvider>
            <ExperimentProvider>
              <PageLoadTracker />
              <AuthProvider>
                <LayoutControlsProvider>
                  {getLayout(<Component {...pageProps} />, tisaSans.className)}
                </LayoutControlsProvider>
              </AuthProvider>
            </ExperimentProvider>
          </ApolloProvider>
        </SkeletonTheme>
        <CookieBlocker />
      </BrandDataProvider>
    </Sentry.ErrorBoundary>
  );
}

// Need to get cookies sent from the request and pass them to the app for
// server-side prerendering
CustomerApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);
  return {
    ...appProps,
    host: appContext.ctx.req?.headers?.host,
  };
};
