import * as React from 'react';
import type { SingleIconProps } from '../types';
const SvgClock = (props: SingleIconProps) => (
  <svg
    width={props.size}
    height={props.size}
    viewBox="0 0 24 24"
    fill="none"
    aria-label={props.accessibilityLabel}
    className={'flex-shrink-0 ' + props.className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2ZM12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 14.1217 19.1571 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20ZM15 14C15.4482 14.0234 15.857 13.7454 16 13.32C16.0836 13.0614 16.0583 12.78 15.9301 12.5404C15.8018 12.3008 15.5815 12.1238 15.32 12.05L13 11.28V7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7V12C10.9989 12.4299 11.2727 12.8124 11.68 12.95L14.68 13.95C14.784 13.9805 14.8916 13.9973 15 14Z"
      fill={props.color}
    />
  </svg>
);
export default SvgClock;
