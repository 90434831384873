import React from 'react';
import colors from '../../../utils/colors';
import { CustomIconProps } from './types';

export const Windows = ({ size, color }: CustomIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.1379 8H55.8621V54.4593H16.1379V8Z"
        fill={colors.brand.gray.medium}
      />
      <path
        d="M19.4482 11.3186H52.5517V51.1408H19.4482V11.3186Z"
        fill="white"
      />
      <path
        d="M34.3448 8H37.6551V54.4593H34.3448V8Z"
        fill={colors.brand.gray.medium}
      />
      <path
        d="M19.4482 11.3186H20.2758V25.0075H19.4482V11.3186Z"
        fill={color || colors.brand.gray.dark}
      />
      <path
        d="M19.4482 12.1482V11.3186L34.3448 11.3186V12.1482L19.4482 12.1482Z"
        fill={color || colors.brand.gray.dark}
      />
      <path
        d="M19.4482 29.1555V28.3259H34.3448V29.1555L19.4482 29.1555Z"
        fill={color || colors.brand.gray.dark}
      />
      <path
        d="M37.6552 12.1482V11.3186L52.5517 11.3186V12.1482L37.6552 12.1482Z"
        fill={color || colors.brand.gray.dark}
      />
      <path
        d="M37.6552 29.1555V28.3259H52.5517V29.1555L37.6552 29.1555Z"
        fill={color || colors.brand.gray.dark}
      />
      <path
        d="M19.4482 28.3259H20.2758V51.1407H19.4482V28.3259Z"
        fill={color || colors.brand.gray.dark}
      />
      <path
        d="M55.0345 8H55.8621V54.4593H55.0345V8Z"
        fill={color || colors.brand.gray.dark}
      />
      <path
        d="M37.6552 11.3186H38.4827V25.0075H37.6552V11.3186Z"
        fill={color || colors.brand.gray.dark}
      />
      <path
        d="M37.6552 28.3259H38.4827V51.1407H37.6552V28.3259Z"
        fill={color || colors.brand.gray.dark}
      />
      <path
        d="M16.1379 28.3259L16.1379 25.0074L55.0345 25.0074L55.0345 28.3259L16.1379 28.3259Z"
        fill={colors.brand.gray.medium}
      />
      <path
        d="M12.9796 54.4592H59.0204L60 61.9259H12L12.9796 54.4592Z"
        fill={colors.brand.gray.light}
      />
      <rect
        x="12"
        y="61.9258"
        width="48"
        height="2.07407"
        fill={color || colors.brand.black}
      />
    </svg>
  );
};
