import * as React from 'react';
import type { SingleIconProps } from '../types';
const SvgRibbon = (props: SingleIconProps) => (
  <svg
    width={props.size}
    height={props.size}
    viewBox="0 0 24 24"
    fill="none"
    aria-label={props.accessibilityLabel}
    className={'flex-shrink-0 ' + props.className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.9 12.1991L18.19 8.99913L18.9 5.79913C19.0117 5.2917 18.7162 4.78325 18.22 4.62913L15.09 3.62913L12.68 1.41913C12.2964 1.06341 11.7035 1.06341 11.32 1.41913L8.90996 3.63913L5.77996 4.63913C5.28837 4.79296 4.99404 5.29504 5.09996 5.79913L5.80996 8.99913L5.09996 12.1991C4.98825 12.7066 5.28376 13.215 5.77996 13.3691L7.99996 14.0691V20.9991C8.00185 21.366 8.20447 21.7024 8.52789 21.8755C8.8513 22.0487 9.24358 22.0309 9.54996 21.8291L12 20.1991L14.45 21.8291C14.7564 22.0309 15.1486 22.0487 15.472 21.8755C15.7955 21.7024 15.9981 21.366 16 20.9991V14.0691L18.22 13.3691C18.7162 13.215 19.0117 12.7066 18.9 12.1991ZM11.45 18.1991L9.99996 19.1991V15.3591L11.32 16.5791C11.5059 16.7482 11.7487 16.841 12 16.8391C12.2504 16.8363 12.4916 16.7441 12.68 16.5791L14 15.3591V19.1291L12.55 18.1991C12.2162 17.9794 11.7837 17.9794 11.45 18.1991ZM13.9 12.7591C14.0107 12.6612 14.1399 12.5864 14.28 12.5391L16.75 11.7391L16.18 9.21913C16.1501 9.07399 16.1501 8.92427 16.18 8.77913L16.75 6.25913L14.28 5.48913C14.1399 5.44183 14.0107 5.36704 13.9 5.26913L12 3.51913L10.1 5.26913C9.98921 5.36704 9.86002 5.44183 9.71996 5.48913L7.24996 6.25913L7.81996 8.77913C7.84987 8.92427 7.84987 9.07399 7.81996 9.21913L7.24996 11.7391L9.71996 12.5091C9.86002 12.5564 9.98921 12.6312 10.1 12.7291L12 14.4791L13.9 12.7591Z"
      fill={props.color}
    />
  </svg>
);
export default SvgRibbon;
