import Tile from '@/components/ui/Tile';
import Link from 'next/link';
import { useBrandData } from 'src/hooks/useBrandData';

const Footer = () => {
  const { appName, baseUrl } = useBrandData();
  return (
    <Tile role="contentinfo">
      <Link
        href={`${baseUrl}/terms`}
        className="text-purple hover:text-darkPurple hover:underline mr-2"
      >
        Terms
      </Link>
      <Link
        href={`${baseUrl}/privacy`}
        className="text-purple hover:text-darkPurple hover:underline mr-2"
      >
        Privacy
      </Link>
      <Link
        href={`${baseUrl}/privacy/optout`}
        className="text-purple hover:text-darkPurple hover:underline mr-2"
      >
        Do Not Sell My Personal Information
      </Link>
      <p>
        © {appName} 2015 - {new Date().getFullYear()}
      </p>
    </Tile>
  );
};

export default Footer;
