import * as React from 'react';
import type { SingleIconProps } from '../types';
const SvgCalendar = (props: SingleIconProps) => (
  <svg
    width={props.size}
    height={props.size}
    viewBox="0 0 24 24"
    fill="none"
    aria-label={props.accessibilityLabel}
    className={'flex-shrink-0 ' + props.className}
  >
    <g clipPath="url(#clip0_86_258)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 5H18V3C18 2.44772 17.5523 2 17 2C16.4477 2 16 2.44772 16 3V5H8V3C8 2.44772 7.55228 2 7 2C6.44772 2 6 2.44772 6 3V5H5C3.34315 5 2 6.34315 2 8V19C2 20.6569 3.34315 22 5 22H19C20.6569 22 22 20.6569 22 19V8C22 6.34315 20.6569 5 19 5ZM4 8C4 7.44772 4.44772 7 5 7H19C19.5523 7 20 7.44772 20 8V9H4V8ZM19 20C19.5523 20 20 19.5523 20 19V11H4V19C4 19.5523 4.44772 20 5 20H19Z"
        fill={props.color}
      />
    </g>
    <defs>
      <clipPath id="clip0_86_258">
        <rect width={24} height={24} fill={props.color} />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCalendar;
