const colors = {
  brand: {
    black: '#000000',
    white: '#FFFFFF',
    purple: '#5E71C4',
    darkPurple: '#4B5BA1',
    blue: '#33a6f8',
    darkBlue: '#007bff',
    darkerBlue: '#2965D2',
    red: '#E1745D',
    darkerRed: '#C2553E',
    yellow: '#F5C34A',
    green: '#D6E28E',
    darkGreen: '#53a318',
    darkerGreen: '#1F730A',
    teal: '#92E0C1',
    orange: '#EBA670',
    gray: {
      lightest: '#F7F7F7',
      light: '#E4E3E3',
      medium: '#DDDDDD',
      dark: '#727272',
      darker: '#424242',
    },
  },
};

export default colors;
