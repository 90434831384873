import { getDefaultTimezone } from './helper';
import { makeLogger } from './makeLogger';
import * as Sentry from '@sentry/nextjs';
import Mixpanel from '@/utils/mixpanel';

const log = makeLogger('updateTimezoneFromApi');

/**
 *
 * updates the local timezone from the api
 *
 * if apiTz is provided, it will be used, but if the api doesn't return a
 * timezone, the browser's timezone will be used
 *
 * This will update localStorage, sentry, and mixpanel
 */
export function updateLocalTimezoneFromApi({
  apiTz,
}: {
  apiTz: string;
  customerId?: string;
}) {
  const tz = apiTz || getDefaultTimezone();
  if (
    typeof localStorage !== 'undefined' &&
    localStorage.getItem('timezone') !== tz
  ) {
    localStorage.setItem('timezone', tz);
  }
  log('timezones', {
    api: apiTz,
    browser: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone,
    default: getDefaultTimezone(),
  });
  if (apiTz) {
    log('identify user', apiTz);
    Sentry.setUser({ email: apiTz });
    Mixpanel.register({
      apiTimezone: apiTz,
      browserTimezone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone,
      defaultTimezone: getDefaultTimezone(),
    });
  }
}
